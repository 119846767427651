import React, { useState, useEffect, useRef } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import homeButton from "../../assets/homeBtn.png";
import FirstThenBoard from "../FirstThenBoard";
import WelcomeBack from "./WelcomeBack";
import MissionThisWeek from "./MissionThisWeek";
import School from "../School";
import Ready from "../Ready";
import Section1 from "./Section1";
import Section2 from "./Section2";
import Section3 from "./Section3";
import Section4 from "./Section4";
import Mission1Complete from "./Mission1Complete";
import sectionsBG from "../../assets/sectionsBG.png";
import PirateQuiz from "./PirateQuiz";
import MissionPath1 from "./MissionPath1";
import Mission1Intro from "./Mission1Intro";
import MissionPath2 from "./MissionPath2";
import MissionPath3 from "./MissionPath3";
import MissionPath4 from "./MissionPath4";
import Badge from "./Badge";
import MissionPath5 from "./MissionPath5";
import EndMission from "./EndMission";
import MissionPath6 from "./MissionPath6";
import ProgressBar from "../ProgressBar";
import SeeYou1 from "./SeeYou1";
import helpAudio from "../../assets/audios/INeedHelp.mp3";
import BonusPoint from "../BonusPoint";
import BreakTimer from "../BreakTImer";
import speaker from "../../assets/speaker.png";
import Slide20 from "../../assets/audios/speech/Common/CommonMissionThisWeek.mp3";
// import VisualAudio from "../../assets/audios/speech/Common/MissionPath4.mp3";
import path5 from "../../assets/audios/speech/Common/CommonMissionPath5.mp3";
import Section4Correct from "../../assets/audios/speech/Mission1/4.1.mp3";
import Section4Incorrect from "../../assets/audios/speech/Mission1/4.2.mp3";
import Slide2 from "../../assets/audios/speech/Mission1/Mission1Slide2.mp3";
import MissionPath4Audio from "../../assets/audios/speech/Mission1/MissionPath4.mp3";
import MissionThisWeekAudio from "../../assets/audios/speech/Mission1/CommonMissionThisWeek.mp3";
import Slide11 from "../../assets/audios/speech/Mission1/MissionPath3.mp3";
import Slide10 from "../../assets/audios/speech/Mission1/10.mp3";
import Slide40 from "../../assets/audios/speech/Mission1/Mission1Slide40.mp3";
import MissionPath2Audio from "../../assets/audios/speech/Mission1/MissionPath2.mp3";
import MissionPath5Audio from "../../assets/audios/speech/Mission1/MissionPath5.mp3";
import MissionPath6Audio from "../../assets/audios/speech/Mission1/MissionPath6.mp3";
import WelcomeBackAudio from "../../assets/audios/speech/Mission1/Mission1Slide4.mp3";
import MissionthisWeekAudio from "../../assets/audios/speech/Mission1/M1pg5Missionthisweek.mp3";
import SchoolAudio from "../../assets/audios/speech/Common/School.mp3";
import firstThenAudio from "../../assets/audios/speech/Common/firstThenAudio.mp3";
import BodyPartsAudio from "../../assets/audios/speech/Mission1/BodypartSound.mp3";
import BodyPartsPainAudio from "../../assets/audios/speech/Mission1/PainInBodyParts.mp3";
import M1PirateQuizAudio from "../../assets/audios/speech/Mission1/M1PirateQuiz.mp3";
import SpecialBadge from "../../assets/audios/speech/Mission1/SpecialBadge.mp3";
import BonusAudio from "../../assets/audios/speech/Mission1/BonusAudio.mp3";
import Congratulations from "../../assets/audios/speech/Mission1/mission1_congratulations.mp3";
import axios from "axios";
const Mission1 = () => {
  const [section, setSection] = useState(1);
  const [inputValue, setInputValue] = useState(
    sessionStorage.getItem("firstThenInput") || ""
  ); // Initialize with session storage value
  const navigate = useNavigate();
  const location = useLocation();
  const carrier = location.state?.carrier;
  const [isBreak, setIsBreak] = useState(false);
  const [coinCounter, setCoinCounter] = useState(1); // Initialize coin counter
  const [submitted, setSubmitted] = useState(false);
  const userId = carrier?.userId;
  

  const audioRef = useRef(null);

  const saveStarsCollected = async () => {
    try {
      const response = await axios.post(
        "https://api.feelingbetterasd.com/saveStarsCollected",
        {
          userId: userId,
          missionId: 1, // Replace with the actual mission ID
          starsCollected: 11, // Replace with the actual stars collected
          totalStars: 11, // Replace with the actual total stars
        }
      );
      if (response.status === 200) {
        const { data } = response.data;
        console.log("Success", data);
      }
    } catch (error) {
      console.error("Error saving stars:", error);
    }
  };

  if (section === 22) {
    saveStarsCollected();
  }

  const handlePrevious = () => {
    if (section === 6) {
      sessionStorage.removeItem("schoolQuizCompleted");
    }
    if (section === 12) {
      sessionStorage.removeItem("allPartsCompleted");
    }
    if (section === 15) {
      sessionStorage.removeItem("section4QuizCompleted");
    }
    if (section > 1) setSection(section - 1);
  };

  const handleNext = () => {
    // Check if the current section requires quiz completion
    if (
      (section === 6 && !sessionStorage.getItem("schoolQuizCompleted")) ||
      (section === 12 && !sessionStorage.getItem("allPartsCompleted")) ||
      (section === 16 && !sessionStorage.getItem("section4QuizCompleted"))
    ) {
      toast.error("Please complete the quiz before proceeding.");
      return;
    }

    if (section < 23) {
      setSection(section + 1);
    } else {
      navigate("/map", { state: { carrier } });
    }
    console.log(section);
  };

  // if ([16].includes(section)) {
  //   setSubmitted(false); 
  // }

  const isPreviousDisabled = section === 1;
  const isLastPage = section === 23;

  const totalSections = 23;

  const handleHomeClick = () => {
    navigate(-1);
  };

  // Function to handle input change
  const handleInputChange = (value) => {
    setInputValue(value);
    sessionStorage.setItem("firstThenInput", value); // Save to session storage
  };

  // Function to show toast notification
  const handleBreakClick = () => {
    toast.success("You can take a break now, your progress has been saved!");
    setIsBreak(true);
  };

  // Function to play help audio
  const handleHelpClick = () => {
    const audio = new Audio(helpAudio);
    audio.play();
  };

  // Function to handle closing the break timer
  const handleBreakClose = () => {
    setIsBreak(false);
  };

  // Function to handle play text to speech
  const handleSpeech = (subsection = section) => {
    const sectionAudioMap = {
      2: Slide2,
      3: firstThenAudio,
      4: WelcomeBackAudio,
      5: MissionthisWeekAudio,
      6: SchoolAudio,
      7: MissionPath2Audio,
      9: BodyPartsAudio,
      10: Slide10,
      11: Slide11,
      12: BodyPartsPainAudio,
      13: BonusAudio,
      14: MissionPath4Audio,
      15: M1PirateQuizAudio,
      15.1: Section4Correct,
      15.2: Section4Incorrect,
      17: SpecialBadge,
      18: MissionPath5Audio,
      19: Congratulations,
      20: MissionThisWeekAudio,
      21: MissionPath6Audio,
      22: Slide40,
      // Add mappings for other sections here
    };

    const audioFile = sectionAudioMap[section];
    if (audioFile) {
      // Pause the previous audio if it's playing
      if (audioRef.current) {
        audioRef.current.pause();
      }
      // Create a new audio instance and play it
      audioRef.current = new Audio(audioFile);
      audioRef.current.play();
    }
  };

  useEffect(() => {
    return () => {
      if (audioRef.current) {
        audioRef.current.pause();
        audioRef.current = null; // Clean up the audio reference
      }
    };
  }, [section]);

  const handleCoinUpdate = (newCoins) => {
    setCoinCounter((prevCoins) => prevCoins + newCoins); // Update coin counter
    console.log("Coin" + coinCounter);
  };

  return (
    <div className="h-screen flex flex-col justify-between pb-4 pr-3 pl-3 pt-1 bg-[#3FA7D6] fadeIn">
      <ToastContainer />
      {/* Top Bar */}
      <div className="flex flex-row items-center justify-between w-full">
        <img
          src={homeButton}
          alt="home-btn"
          className="cursor-pointer hover:scale-110 hover:invert"
          onClick={handleHomeClick}
        />
        <button
          className="bg-[#EE6352] text-white font-bold rounded-lg px-6 py-2 ml-4 transition duration-200 hover:bg-[#ebdbc4] hover:text-[#374151] border-2 border-transparent hover:border-[#9c6334]"
          onClick={handleHelpClick}
        >
          Help
        </button>
        <div className="flex-grow ml-0">
          <ProgressBar currentSection={section} totalSections={totalSections} />
        </div>
        <button
          className="bg-[#EE6352] text-white font-bold rounded-lg px-6 py-2 mr-4 transition duration-200 hover:bg-[#ebdbc4] hover:text-[#374151] border-2 border-transparent hover:border-[#9c6334]"
          onClick={handleBreakClick}
        >
          Break
        </button>
        <img
          src={speaker}
          alt="speaker"
          className={`hover:scale-110 ease-in-out transition duration-300 ${
            submitted ? "opacity-50 cursor-not-allowed" : ""
          }`}
          onClick={() => {
            if (!submitted) {
              handleSpeech();
            }
          }}
        />
      </div>
      {/* Render ProgressBar component */}
      <div className="sections flex-grow">
        <div
          className="h-full flex items-center justify-center relative"
          style={{
            backgroundImage: `url(${sectionsBG})`,
            backgroundPosition: "center",
            backgroundSize: "contain",
            backgroundRepeat: "no-repeat",
          }}
        >
          <div className="absolute inset-0 flex flex-col justify-center items-center pt-4 md:pt-0">
            {isBreak ? (
              <BreakTimer onClose={handleBreakClose} />
            ) : (
              console.log("")
            )}
          </div>
          <div className="absolute inset-0 flex flex-col justify-center items-center pt-4 md:pt-0">
            {section === 1 && <Mission1Intro />}
            {section === 2 && <MissionPath1 />}
            {section === 3 && (
              <FirstThenBoard
                inputValue={inputValue}
                onInputChange={handleInputChange}
              />
            )}
            {section === 4 && (
              <WelcomeBack carrier={carrier} onNext={handleNext} />
            )}
            {section === 5 && (
              <MissionThisWeek
                onNext={handleNext}
                carrier={carrier}
                onCoinUpdate={handleCoinUpdate}
              />
            )}
            {section === 6 && (
              <School carrier={carrier} onCoinUpdate={handleCoinUpdate} />
            )}
            {section === 7 && <MissionPath2 />}
            {section === 8 && <Ready onNext={handleNext} />}
            {section === 9 && <Section1 />}
            {section === 10 && <Section2 />}

            {/* bonus point done  */}
            {/* {section === 11 && <BonusPoint />} */}
            {section === 11 && <MissionPath3 />}
            {section === 12 && <Section3 />}
            {/* bonus point */}
            {section === 13 && <BonusPoint />}
            {section === 14 && <MissionPath4 />}
            {section === 15 && <PirateQuiz carrier={carrier} />}
            {section === 16 && (
              <Section4
                handlePrevious={handlePrevious}
                carrier={carrier}                            
                onCoinUpdate={handleCoinUpdate}
              />
            )}
            {section === 17 && <Badge />}
            {section === 18 && <MissionPath5 />}
            {section === 19 && <Mission1Complete carrier={carrier} />}
            {section === 20 && <EndMission />}
            {section === 21 && <MissionPath6 />}
            {section === 22 && <SeeYou1 carrier={carrier} />}
            {section === 23 && (
              <FirstThenBoard
                inputValue={inputValue}
                onInputChange={handleInputChange}
              />
            )}
          </div>
        </div>
      </div>
      <div className="flex justify-around">
        {/* "Previous" button */}
        <button
          className="px-12 py-4 rounded-full bg-[#9c6334] font-bold text-white tracking-widest uppercase transform hover:scale-110 hover:bg-[#623e20] transition-colors duration-200"
          onClick={handlePrevious}
          disabled={isPreviousDisabled}
          style={{
            backgroundColor: "#3FA7D6",
            color: "#fffff",
          }}
        >
          <svg
            class="w-10 h-10 transform rotate-180"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 14 10"
          >
            <path
              stroke="currentColor"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M1 5h12m0 0L9 1m4 4L9 9"
            />
          </svg>
          <span class="sr-only">Previous</span>
        </button>

        <div className="text-center text-xs text-white mt-6">
          <span style={{ fontSize: "1rem" }}>
            © 2024 | University of Galway, Ireland
          </span>
        </div>

        {/* "Next" button */}
        <button
          className="px-12 py-4 rounded-full bg-[#9c6334] font-bold text-white tracking-widest uppercase transform hover:scale-110 hover:bg-[#623e20] transition-colors duration-200"
          onClick={handleNext}
          style={{
            backgroundColor: "#3FA7D6",
          }}
        >
          <svg
            class="w-10 h-10"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 14 10"
          >
            <path
              stroke="currentColor"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M1 5h12m0 0L9 1m4 4L9 9"
            />
          </svg>
          <span class="sr-only">Next</span>
        </button>
      </div>
    </div>
  );
};

export default Mission1;
