import React, { useEffect, useState } from "react";
import confettiGif from "../assets/videos/Celebrations/confetti.gif";
import axios from "axios";
import celebrationSound from "../assets/audios/Kids Cheering.mp3";
import deckhand from "../assets/Ranks/Deckhand.png";
import striker from "../assets/Ranks/Striker.png";
import powderMonkey from "../assets/Ranks/Powder Monkey.png";
import navigator from "../assets/Ranks/Navigator.png";
import coin from "../assets/Icons/Coin.png";

const MissionComplete = ({
  completedMissionId,
  rank,
  userId,
  currentMissionId,
  currentMissionPage,
  newStars,
  prevStars,
  coins,
}) => {
  const [totalStars, setTotalStars] = useState(0); // Initialize totalStars state
  const rankImages = [null, deckhand, striker, powderMonkey, navigator];

  useEffect(() => {
    // Calculate the total coins by adding the previous coins and the new coins
    setTotalStars(prevStars + newStars);
  }, [prevStars, newStars]);

  useEffect(() => {
    // Define the payload containing mission details
    const payload = {
      userId: userId,
      pirateRank: rank,
      completedMissionId: completedMissionId,
      currentMissionId: currentMissionId, // Update with the current mission ID if applicable
      currentMissionPage: currentMissionPage, // Update with the current mission page if applicable
      totalStars: totalStars, // Update with the total coins collected if applicable
    };
    // Make a POST request to save mission details
    axios
      .post(
        "https://api.feelingbetterasd.com/mission/saveMissionDetailsForUser",
        payload
      )
      .then((response) => {
        // console.log(currentMissionId);
        console.log("Mission details saved:", response.data);
      })
      .catch((error) => {
        console.error("Error saving mission details:", error);
      });
  }, [completedMissionId, rank, totalStars]); // Include totalStars in the dependency array

  useEffect(() => {
    // Play celebration audio when the component mounts
    const audio = new Audio(celebrationSound);
    audio.play();

    // Clean up the audio when the component unmounts
    return () => {
      audio.pause();
      audio.currentTime = 0;
    };
  }, []);

  return (
    <div className="relative w-80 h-72 text-sm max-w-4xl bg-transparent md:w-[60%] lg:h-96 xl:h-1/2 xl:pr-7 fadeIn">
      <h2 className="font-bold lg:mb-2 text-center text-[#F79D84] md:text-xl md:mt-7 lg:text-3xl font-gillSans">
        Congratulations! You finished Mission {completedMissionId}!
      </h2>
      <div className="flex justify-center items-center text-gray-700 text-xl font-gillSans">
        <ol className="lg:text-xl lg:mt-10 md:mt-10">
          <li>Well done on getting {coins} coins!</li>
          <li className="mt-5">
            <img src={coin} className="w-20 ml-20 spinner" alt="coin" />
          </li>
          <li className="lg:mt-5 md:mt-3">
            You have been upgraded to {rank} <br /> on Captain Charlie's ship!
          </li>
        </ol>
        {completedMissionId >= 1 && completedMissionId < rankImages.length && (
          <div className="flex justify-center mt-8 ml-8">
            <img
              src={rankImages[completedMissionId]}
              alt={`${rank} Badge`}
              className="w-52 h-52"
            />
          </div>
        )}
        <img
          src={confettiGif}
          alt="confetti"
          className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 pointer-events-none"
          style={{ width: "60%", height: "auto" }}
        />
      </div>
      <img
        src={confettiGif}
        alt="confetti"
        className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 pointer-events-none"
        style={{ width: "60%", height: "auto" }}
      />
    </div>
  );
};

export default MissionComplete;
