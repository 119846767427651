import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import loginBG from "../assets/loginBG.png";
import fbLogo from "../assets/fbLogo.png";
import uniLogo from "../assets/uniLogo.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import axios from "axios";

const Login = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [rememberMe, setRememberMe] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();

    // const carrier = {
    //   firstName: "John",
    //   lastName: "Doe",
    //   userId: 1,
    // };
    // navigate("/home", { state: { carrier } });

    try {
      const response = await axios.post(
        "https://api.feelingbetterasd.com/user/login",
        {
          userName: username,
          userPassword: password,
        }
      );
      if (response.status === 200) {
        // navigate("/home");
        // console.log("Success", response);
        const { data } = response.data;

        console.log("First Name : " + data.firstName);
        console.log("Last Name : " + data.lastName);
        navigate("/home", { state: { carrier: data } });
      }
    } catch (error) {
      setError("Invalid username or password");
    }
  };

  const signUpHandler = () => {
    navigate("/sign-up");
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };
  const handleClick = () => {
    console.log("Click");
  };
  return (
    <div className="flex items-center justify-center min-h-screen bg-gray-100">
      <div className="w-full max-w-4xl">
        <form
          onSubmit={handleSubmit}
          className="bg-white shadow-md rounded px-12 pt-8 pb-10 mb-6"
        >
          <div className="flex flex-row gap-32">
            <div>
              <div className="flex justify-between items-center gap-10 -mb-5">
                <img
                  src={fbLogo}
                  alt="Feeling-Better-Logo"
                  className=" w-40 items-center"
                />
                <img
                  src={uniLogo}
                  alt="University-Logo"
                  className="w-60 items-center"
                />
              </div>
              <div className="mb-4">
                <h2 className="mb-6 font-montserrat text-2xl font-semibold">
                  Log In
                </h2>
                <label
                  className="block text-gray-700 text-sm font-semibold mb-3"
                  htmlFor="username"
                >
                  Username
                </label>
                <input
                  type="text"
                  id="username"
                  placeholder="Username"
                  value={username}
                  onChange={(e) => setUsername(e.target.value)}
                  className="shadow appearance-none border rounded w-full py-3 px-4 text-gray-700 leading-tight focus:outline-none focus:shadow-outline font-gillSans"
                />
              </div>
              <div className="mb-1 relative">
                <label
                  className="block text-gray-700 text-sm font-semibold mb-3"
                  htmlFor="password"
                >
                  Password
                </label>
                <input
                  type={showPassword ? "text" : "password"}
                  id="password"
                  placeholder="******************"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  className="shadow appearance-none border rounded w-full py-3 px-4 text-gray-700 mb-4 leading-tight focus:outline-none focus:shadow-outline"
                />
                <span
                  className="absolute bottom-5 right-0 pr-3 flex items-center text-sm leading-5 cursor-pointer"
                  onClick={togglePasswordVisibility}
                >
                  <FontAwesomeIcon
                    icon={showPassword ? faEyeSlash : faEye}
                    className="h-5 w-5"
                  />
                </span>
              </div>
              <div className="mb-4">
                
              </div>
              <div className="mb-6">
                <label
                  className="block text-gray-700 text-sm font-semibold mb-2"
                  htmlFor="rememberMe"
                >
                  <input
                    type="checkbox"
                    id="rememberMe"
                    checked={rememberMe}
                    onChange={(e) => setRememberMe(e.target.checked)}
                    className="mr-2 leading-tight font-gillSans"
                  />
                  Remember Me
                </label>
              </div>
              <div className="flex items-center justify-between">
                <button
                  type="submit"
                  className="bg-[#3fa7d6] hover:bg-[#4ac4fc] text-white font-bold py-2 px-20 rounded focus:outline-none focus:shadow-outline"
                >
                  Login
                </button>
              </div>
            </div>
            <div className="flex items-center">
              <img src={loginBG} alt="captain-charlie" className="w-96 h-96" />
            </div>
          </div>
        </form>
        <div className="text-center">
          <span style={{ fontSize: "1rem" }}>© 2024 | University of Galway, Ireland</span>
        </div>
      </div>
    </div>
  );
};

export default Login;
