import React, { useState } from "react";
import cptCharlie from "../../assets/cptCharlie.png";

const ActivityTime = ({ onNext }) => {
  const [ready, setReady] = useState(false);

  const handleStart = () => {
    setReady(true);
    onNext();
  };

  return (
    <div className=" w-80 h-72 text-sm max-w-4xl bg-transparent md:w-[60%] lg:h-96 xl:h-1/2 xl:pr-7 fadeIn -mt-10">
      <h2 className="text-xl font-bold mb-2 text-center text-[#F79D84] md:text-2xl md:mt-2 lg:text-4xl font-gillSans">
        Task Time!
      </h2>
      <div className="text-gray-700 text-center flex flex-row items-start font-gillSans">
        <div className=" lg:w-96 md:w-64">
          <img src={cptCharlie} alt="cptCharlie" />
        </div>
        <div>
          <p className=" mt-10 mb-10 lg:text-2xl md:text-xl">Are you ready?</p>
          {ready ? (
            <p>Started!</p>
          ) : (
            <button
              className="px-8 py-2 rounded-md bg-green-500 text-white font-bold transition duration-200 hover:bg-[#ebdbc4] hover:text-[#374151] border-2 border-transparent hover:border-black"
              onClick={handleStart}
            >
              Yes!
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default ActivityTime;
