import React from "react";
import bodyPartVideo from "../../assets/videos/Point to Body Part.mp4";
import coin from "../../assets/Icons/Coin.png";
import coins from "../../assets/audios/coins.mp3";

const BodyPartSection = () => {
  const audioPlay = () => {
    // Play celebration audio when the component mounts
    const audio = new Audio(coins);
    audio.play();
  };

  audioPlay();
  
  return (
    <div className="w-80 h-72 text-sm max-w-4xl bg-transparent md:w-[60%] lg:h-96 xl:h-1/2 xl:pr-7 fadeIn">
      <div className="flex flex-row items-center justify-center gap-2">
        <h2 className="text-xl font-bold mb-2 text-center text-[#F79D84] md:text-2xl md:mt-2 lg:text-4xl font-gillSans flex items-center justify-center">
          Pointing to your Body Part
          <img src={coin} className="w-12 ml-2 heartbeat" alt="coin" />
        </h2>
      </div>
      <div className="text-gray-700 text-lg font-gillSans">
        <ol className="lg:mt-10 text-xl">
          <li className="lg:mt-6">
            When a body part hurts, we can point to the body part to show where
            it hurts.
          </li>
          <li className="lg:mt-6">Watch the video to learn more:</li>
          <div className="flex lg:mt-5 md:mt-2 justify-center pr-6 gap-5 lg:pr-8">
            <video className="lg:w-96 md:w-80" controls>
              <source src={bodyPartVideo} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          </div>
        </ol>
      </div>
    </div>
  );
};

export default BodyPartSection;
