import React, { useState } from "react";
import axios from "axios";
import coin from "../../assets/Icons/Coin.png";
import coins from "../../assets/audios/coins.mp3";
import painScale0 from "../../assets/pain scale/0.png";
import painScale2 from "../../assets/pain scale/2.png";
import painScale4 from "../../assets/pain scale/4.png";
import painScale6 from "../../assets/pain scale/6.png";
import painScale8 from "../../assets/pain scale/8.png";
import painScale10 from "../../assets/pain scale/10.png";

const PiratePainQuiz = ({ carrier }) => {
  const [selectedOption, setSelectedOption] = useState(null);
  const [feedback, setFeedback] = useState("");
  const [feedbackClass, setFeedbackClass] = useState("");
  const [clickedIndex, setClickedIndex] = useState(null);
  const [submitted, setSubmitted] = useState(false);
  const [showSecondQuestion, setShowSecondQuestion] = useState(false);
  const [incorrectAttempts, setIncorrectAttempts] = useState(0); // To track incorrect attempts
  const [hinted, setHinted] = useState(false); // To show hint after 3 incorrect attempts
  const [incorrectImageAttempts, setIncorrectImageAttempts] = useState(0); // For tracking incorrect attempts in second question
  const handleSubmit = async () => {
    try {
      const payload = [
        {
          userId: carrier.userId,
          missionId: "M4",
          pageNum: 36,
          activityId: "M4PirateQuiz",
          activityValue: JSON.stringify({
            question: "Quiz",
            result: "correct",
          }),
        },
      ];
      const response = await axios.post(
        "https://api.feelingbetterasd.com/mission/activity/saveMissionActivityDetails",
        payload
      );
      console.log("Response:", response.data);
    } catch (error) {
      console.error("Error saving mission activity:", error);
    }
  };
  const audioPlay = () => {
    const audio = new Audio(coins);
    audio.play();
  };

  const painScales = [
    painScale0,
    painScale2,
    painScale4,
    painScale6,
    painScale8,
    painScale10,
  ];

  const handleOptionClick = (option) => {
    setSelectedOption(option);
    setHinted(false); // Reset hint when an option is clicked

    if (option === 2) {
      setFeedbackClass("bounceIn text-green-500");
      if (carrier && carrier.firstName) {
        setFeedback(
          `Correct answer, Excellent Job Striker ${carrier.firstName}`
        );
      } else {
        setFeedback("Correct answer, Excellent Job Striker!");
      }

      // Clear the feedback and show the second question after 2 seconds
      setTimeout(() => {
        setFeedback("");
        setShowSecondQuestion(true);
      }, 2000);
    } else {
      setFeedback("Oops! Let's try again");
      setFeedbackClass("shake text-red-500");

      // Increment incorrect attempts and show hint if attempts are >= 3
      setIncorrectAttempts((prev) => prev + 1);
      if (incorrectAttempts + 1 >= 3) {
        setHinted(true); // Show hint after 3 incorrect attempts
      }
    }
  };

  const handleImageClick = async (index) => {
    setClickedIndex(index);

    if (index === 5) {
      setFeedbackClass("lg:text-xl bounceIn text-green-500");
      setFeedback("Well done! The red face shows the most pain!");
      sessionStorage.setItem("pQ4Completed", "true");
      handleSubmit()
    } else {
      setFeedbackClass("shake text-red-500 lg:text-xl");
      setFeedback("Oops! Let's try again.");
      setIncorrectImageAttempts((prev) => prev + 1); // Track incorrect image attempts

      if (incorrectImageAttempts + 1 >= 3) {
        setHinted(true); // Show hint after 3 incorrect image attempts
      }
    }
  };

  return (
    <div className="w-80 h-72 text-sm max-w-4xl bg-transparent md:w-[60%] lg:h-96 xl:h-1/2 xl:pr-7 fadeIn -mt-10">
      <div className="flex flex-row items-center justify-center gap-2">
        <h2 className="text-xl font-bold mb-2 text-center text-[#F79D84] md:text-xl md:mt-2 lg:text-4xl font-gillSans">
          A Pirate's Pain Quiz
        </h2>
        <img src={coin} className="w-12 ml-20 heartbeat" alt="coin" />
      </div>
      <div className="text-gray-700 text-base font-gillSans">
        {!showSecondQuestion ? (
          <>
            {/* Render Question 1 */}
            <p className="mt-5 underline lg:text-xl text-center">
              What is a pain scale used for?
            </p>
            <ol className="text-xl list-decimal flex justify-center flex-col items-center">
              <li
                className={`text-xl mt-5 cursor-pointer hover:scale-110 transform transition duration-300 ease-in-out ${
                  hinted ? "text-gray-500" : ""
                }`}
                onClick={() => handleOptionClick(1)}
              >
                Measuring how tall you are
              </li>
              <li
                className={`mt-5 cursor-pointer hover:scale-110 transform transition duration-300 ease-in-out ${
                  hinted
                    ? "text-yellow-500" // Highlight correct option in yellow if hint is triggered
                    : selectedOption === 2
                    ? "text-green-500"
                    : ""
                }`}
                onClick={() => handleOptionClick(2)}
              >
                To tell your mum/dad or teacher how much something hurts
              </li>
              <li
                className="mt-5 cursor-pointer hover:scale-110 transform transition duration-300 ease-in-out"
                onClick={() => handleOptionClick(3)}
              >
                Counting how many toys I have
              </li>
            </ol>
            {feedback && (
              <p className={`mt-10 text-center lg:text-lg ${feedbackClass}`}>
                {feedback}
              </p>
            )}
            {submitted && (
              <div className="mt-5 text-center text-green-600 font-bold">
                Submitted successfully!
              </div>
            )}
          </>
        ) : (
          // Render Question 2 if the first question is answered correctly
          <div className="text-gray-700 text-base font-gillSans mt-10 fadeIn">
            <ol className="text-xl">
              <li className="mt-1">
                Can you guess which faces show the{" "}
                <span className="font-semibold">most</span> pain?
              </li>
              <li className="mt-4">Touch the face-</li>
              <div className="flex mt-10 justify-around items-center gap-5 max-w-3xl">
                {painScales.map((src, index) => (
                  <div className="lg:text-xl flex items-center flex-col" key={index}>
                    <img
                      src={src}
                      alt={`pain-scale-${index}`}
                      className={`text-xl hover:scale-110 transform transition duration-300 ease-in-out cursor-pointer ${
                        hinted && index === 5
                          ? "border-4 border-yellow-500" // Highlight the correct image in yellow if hint is triggered
                          : ""
                      }`}
                      onClick={() => handleImageClick(index)}
                    />
                  </div>
                ))}
              </div>
            </ol>
            {feedback && (
              <div className={`mt-10 text-center lg:text-lg ${feedbackClass}`}>
                {feedback}
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default PiratePainQuiz;
