import React, { useEffect } from "react";
import coldHeadache from "../../assets/videos/cold-headache.mp4";
import coin from "../../assets/Icons/Coin.png";
import coins from "../../assets/audios/coins.mp3";

const Quest3 = () => {
  const audioPlay = () => {
    // Play celebration audio when the component mounts
    const audio = new Audio(coins);
    audio.play();
  };

  useEffect(() => {
    const audio = new Audio(coins);
    // audio.play();

    // Clean up the audio when the component unmounts
    return () => {
      audio.pause();
      audio.currentTime = 0;
    };
  }, []);

  audioPlay();

  return (
    <div className="w-80 h-72 text-sm max-w-4xl bg-transparent md:w-[60%] lg:h-96 xl:h-1/2 xl:pr-7 fadeIn">
      <div className="flex flex-row items-center justify-center gap-2">
        <h2 className="text-xl font-bold mb-2 text-center text-[#F79D84] md:text-2xl md:mt-2 lg:text-4xl font-gillSans">
          Quest 3
        </h2>
        <img src={coin} className="w-12 ml-20 heartbeat" alt="coin" />
      </div>
      <div className="text-gray-700 text-base font-gillSans">
        <ol className="lg:text-xl lg:mt-1 ">
          <li className="lg:mt-6">
            Your last mission is to listen to Annie and find out which body
            parts hurts.
          </li>
          <li className="lg:mt-6">
            Watch the video below and then tell Captain Charlie which body part
            hurts Annie.
          </li>
          <div className="  flex lg:mt-5 md:mt-2 justify-center pr-6 gap-5 lg:pr-8">
            <video className="lg:w-96 md:w-80" controls>
              <source src={coldHeadache} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          </div>
        </ol>
      </div>
    </div>
  );
};

export default Quest3;
