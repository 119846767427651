import React from "react";
import painScale from "../../assets/pain scale/Pain scale.png";

const PSConclusion = ({ carrier }) => {
  return (
    <div className="w-80 h-72 text-sm max-w-4xl bg-transparent md:w-[60%] lg:h-96 xl:h-1/2 xl:pr-7 fadeIn">
      <h2 className="text-xl font-bold mb-2 text-center text-[#F79D84] md:text-2xl md:mt-2 lg:text-4xl font-gillSans">
        Using the Pain Scale
      </h2>
      <div className="text-gray-700 text-base font-gillSans">
        <ol className="lg:text-xl mr-10">
          <li className="mt-4">
            Excellent Job Powder Monkey {carrier?.firstName}!
          </li>
          <li className="mt-4">
            You have done a super job helping Captain Charlie this week.
          </li>
          <li className="mt-4">
            This week you learnt about a special tool called a{" "}
            <span className=" font-extrabold"> "pain scale"</span> to show how
            you are feeling when you have pain.
          </li>
          <div className="flex flex-row justify-around mt-4">
            <img src={painScale} alt="painScale" className=" w-96 mt-6" />
          </div>
        </ol>
      </div>
    </div>
  );
};

export default PSConclusion;
