import React from "react";
import Captain from "../../assets/cptCharlie.png";
import bodyImage from "../../assets/bodyImageResized.png";
import child from "../../assets/Characters/child.png";
const Section2 = () => {
  return (
    <div className="w-80 h-72 text-sm max-w-4xl bg-transparent md:w-[60%] lg:h-96 xl:h-1/2 xl:pr-7 fadeIn -mt-10">
      <h2 className="text-xl font-bold mb-2 text-center text-[#F79D84] md:text-2xl md:mt-2 lg:text-4xl font-gillSans">
        Knowing your body parts
      </h2>
      <div className=" flex justify-evenly items-center">
        <ol className="text-gray-700 text-xl lg:mt-2 font-gillSans">
          <li className=" lg:mt-2">Sometimes our body parts can hurt.</li>
          <li className=" lg:mt-4">
            When a body part hurts it is important to show your mum/dad or
            teacher where the body part hurts.
          </li>
          <li className=" lg:mt-4">
            Showing the body part that hurts to your mum/dad can help you feel
            better. You can show them by:
            <div className=" flex flex-row lg:w-60 ml-24 justify-evenly items-center">
              <div className=" max-lg:hidden">
                <img src={child} alt="child" className=" lg:w-44 md:w-14" />
                <span className="lg:text-lg text-center text-base">
                  Touching your body part.
                </span>
              </div>
              <div className=" max-lg:hidden">
                <p className="ml-5 mr-5 text-center lg:text-base">OR</p>
              </div>
              <div className=" max-lg:hidden">
                <img
                  src={bodyImage}
                  alt="bodyImage"
                  className=" lg:w-14 md:w-6"
                />
                <span className="lg:text-lg text-center text-base">
                  Touching the body map.
                </span>
              </div>
            </div>
            <ol className=" list-decimal mt-2 ml-5 lg:hidden">
              <li>Touching your body part.</li>
              <li className="lg:text-lg mt-2">Touching the body map.</li>
            </ol>
          </li>
        </ol>
        <img src={Captain} className=" lg:w-64 md:w-40" alt="" />
      </div>
    </div>
  );
};

export default Section2;
