import React from "react";
import { NavLink } from "react-router-dom";

const NavBar = ({carrier}) => {
  // console.log(carrier.userId);
  const userId = carrier.userId;
  return (
    <header className="bg-[#267e8c]">
      <ol className="flex flex-row justify-evenly gap-7 px-6 py-6 bg-opacity-50 backdrop-filter backdrop-blur-lg zoomer">
        <NavLink
          to="/parents"
          className="text-white text-lg font-semibold hover:text-black transition duration-300 ease-in-out"
        >
          Parents
        </NavLink>
        <NavLink
          to="/information"
          className="text-white text-lg font-semibold hover:text-black transition duration-300 ease-in-out"
        >
          Information
        </NavLink>
        <NavLink
          to="/resources"
          className="text-white text-lg font-semibold hover:text-black transition duration-300 ease-in-out"
        >
          Resources
        </NavLink>

        {userId === 3 ? (
          <>
            <NavLink
          to="/admin"
          className="text-white text-lg font-semibold hover:text-black transition duration-300 ease-in-out"
        >
          Admin Dashboard
        </NavLink>

          </>) : (<></>)}

        <NavLink
          to="/"
          className="text-white text-lg font-semibold hover:text-black transition duration-300 ease-in-out"
        >
          Sign Out
        </NavLink>
      </ol>
    </header>
  );
};

export default NavBar;
