import React, { useState } from "react";
import axios from "axios";
import coin from "../../assets/Icons/Coin.png";
import coins from "../../assets/audios/coins.mp3";
import cptCharlie from "../../assets/cptCharlie.png";
import mateyMorgan from "../../assets/Characters/MateyMorgan.png";

const Quiz = ({ carrier, onTryAgain }) => {
  const [selectedOption, setSelectedOption] = useState(null);
  const [submitted, setSubmitted] = useState(false);
  const [isCorrect, setIsCorrect] = useState(null);
  const [attempts, setAttempts] = useState(0); // Track the number of attempts

  const audioPlay = () => {
    const audio = new Audio(coins);
    audio.play();
  };

  if (isCorrect) {
    audioPlay();
    sessionStorage.setItem("taskTime4Completed", "true");
  }

  const handleOptionClick = async (option) => {
    setSelectedOption(option);

    const correctAnswer = 2;
    const answerIsCorrect = option === correctAnswer;
    setIsCorrect(answerIsCorrect);
    setSubmitted(true);

    if (!answerIsCorrect) {
      setAttempts((prevAttempts) => prevAttempts + 1);
    }

    let userId = carrier.userId;
    // Hit the API to save quiz activity
    try {
      const payload = [
        {
          userId: userId,
          missionId: "M1",
          pageNum: 6,
          activityId: "M1PainQuiz",
          activityValue: JSON.stringify({
            selectedOption: option,
            feedback: answerIsCorrect ? "Correct" : "Incorrect",
          }),
        },
      ];
      const response = await axios.post(
        "https://api.feelingbetterasd.com/mission/activity/saveMissionActivityDetails",
        payload
      );
      console.log("Response:", response.data);
    } catch (error) {
      console.error("Error saving mission activity:", error);
    }
  };

  const handleTryAgain = () => {
    setSubmitted(false);
    setSelectedOption(null);
    setIsCorrect(null);
    onTryAgain();
  };

  if (submitted) {
    return isCorrect ? (
      // Correct Answer Component
      <div className="flex max-w-lg flex-col bounceIn font-gillSans">
        <div className="flex flex-row items-center justify-center gap-2">
          <h2 className="text-xl font-bold mb-2 text-center text-green-600 md:text-2xl md:mt-2 lg:text-4xl font-gillSans">
            Correct Answer!
          </h2>
          <img src={coin} className="w-12 ml-20 heartbeat" alt="coin" />
        </div>
        <div className="flex flex-row mt-10">
          <img src={cptCharlie} className="w-56" alt="Captain" />
          <ol className="mt-3 lg:text-xl">
            <li>Excellent Job Powder Monkey {carrier?.firstName}! </li>
            <li className="mt-3">
              That's correct! Matey Morgan uses the pain scale and points to the
              different faces to show how much it hurts.
            </li>
          </ol>
        </div>
      </div>
    ) : (
      // Incorrect Answer Component
      <div className="flex max-w-lg flex-col shake font-gillSans">
        <h2 className="text-3xl mt-5 text-center text-red-600">
          Incorrect Answer!
        </h2>
        <div className="flex flex-row mt-10">
          <img src={cptCharlie} className="w-56" alt="Captain" />
          <div className="flex flex-col">
            <ol className="mt-3 text-xl">
              <li>Let's Try Again.</li>
              <li className="mt-3">
                Click on the photo to listen to Matey Morgan again to find out
                how he shows his mum/dad he has pain.
              </li>
              <li className="mt-3 ml-20">
                <img
                  src={mateyMorgan}
                  className="w-20 cursor-pointer"
                  onClick={handleTryAgain}
                  alt="Matey Morgan"
                />
              </li>
              <li className="mt-3">
                Remember if you need help, click the help button at the top of
                the page.
              </li>
            </ol>
            {/* <div className="flex justify-center">
              <button
                className="px-4 py-2 mt-4 w-32 bg-red-800 text-white rounded-md hover:bg-red-600"
                onClick={handleTryAgain}
              >
                Try Again
              </button>
            </div> */}
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="w-80 h-72 text-sm max-w-4xl bg-transparent md:w-[60%] lg:h-96 xl:h-1/2 xl:pr-7 fadeIn">
      <h2 className="text-xl font-bold mb-2 text-center text-[#F79D84] md:text-2xl md:mt-2 lg:text-4xl font-gillSans">
        Task
      </h2>
      <div className="text-gray-700 text-base font-gillSans">
        <p className="mt-5 underline font-semibold lg:text-xl text-center">
          What does Matey Morgan do to show his mum/dad he is in pain?
        </p>
        <ol className="list-decimal text-xl flex justify-center flex-col items-center">
          <li
            className={`mt-5 cursor-pointer hover:scale-110 ${
              attempts >= 3 && 1 === 2
                ? "bg-yellow-200"
                : "hover:text-[#F79D84]"
            } transform transition duration-300 ease-in-out`}
            onClick={() => handleOptionClick(1)}
          >
            Laugh
          </li>
          <li
            className={`mt-5 cursor-pointer hover:scale-110 ${
              attempts >= 3 && 2 === 2
                ? "bg-yellow-200"
                : "hover:text-[#F79D84]"
            } transform transition duration-300 ease-in-out`}
            onClick={() => handleOptionClick(2)}
          >
            Point to the different faces on the pain scale.
          </li>
          <li
            className={`mt-5 cursor-pointer hover:scale-110 ${
              attempts >= 3 && 3 === 2
                ? "bg-yellow-200"
                : "hover:text-[#F79D84]"
            } transform transition duration-300 ease-in-out`}
            onClick={() => handleOptionClick(3)}
          >
            Run
          </li>
        </ol>
      </div>
    </div>
  );
};

export default Quiz;
