import React from "react";
import weekMission from "../../assets/mission path/FOTWM.png";
import recap from "../../assets/mission path/ROLM.png";
import whereItHurts from "../../assets/mission path/WIH.png";
import pirateQuiz from "../../assets/mission path/PQ.png";
import finished from "../../assets/mission path/F.png";
import greenTick from "../../assets/tick.png";

const MissionPath6 = () => {
  const missions = [
    { src: weekMission, isCompleted: true },
    { src: recap, isCompleted: true },
    { src: whereItHurts, isCompleted: true },
    { src: pirateQuiz, isCompleted: true },
    { src: finished, isCompleted: true },
  ];

  return (
    <div className="w-80 h-72 text-sm max-w-4xl bg-transparent md:w-[60%] lg:h-96 xl:h-1/2 xl:pr-7 fadeIn">
      <h2 className="text-xl font-bold mb-2 text-center text-[#F79D84] md:text-2xl md:mt-2 lg:text-4xl font-gillSans">
        Mission 2
      </h2>
      <div className="text-gray-700 text-base font-gillSans">
        <div className="flex flex-row justify-around gap-4 items-center mt-20 ml-14 w-5/6">
          {/* Missions Map */}
          {missions.map((mission, index) => (
            <div key={index} className="relative rounded border-black">
              <img
                src={mission.src}
                alt="missionMap"
                className={`rounded border-black ${
                  mission.isCompleted ? "opacity-50 grayscale" : ""
                } ${mission.isCurrent ? "floater" : ""}`}
              />
              {mission.isCompleted && (
                <img
                  src={greenTick}
                  alt="completed"
                  className="absolute top-0 right-0 w-full h-full"
                />
              )}
            </div>
          ))}
        </div>
        <div className="mt-10 text-center font-gillSans">
          <p className="text-xl">
          Well done, on all your hard work. <br /> Click on the arrow to finish the mission.


          </p>
        </div>
      </div>
    </div>
  );
};

export default MissionPath6;
