import React, { useEffect } from "react";
import Captain from "../../assets/cptCharlie.png";
import confettiGif from "../../assets/videos/Celebrations/confetti.gif";
import celebrationSound from "../../assets/audios/Kids Cheering.mp3";
import coin from "../../assets/Icons/Coin.png";

const BonusPoints = () => {
  useEffect(() => {
    // Play celebration audio when the component mounts
    const audio = new Audio(celebrationSound);
    audio.play();

    // Clean up the audio when the component unmounts
    return () => {
      audio.pause();
      audio.currentTime = 0;
    };
  }, []);

  return (
    <>
      <div className=" relative w-80 h-72 text-sm max-w-4xl bg-transparent md:w-[60%] lg:h-96 xl:h-1/2 xl:pr-7 fadeIn">
        <div className="flex flex-row items-center justify-center gap-2">
          <h2 className="text-xl font-bold mb-2 text-center text-[#F79D84] md:text-2xl md:mt-2 lg:text-4xl font-gillSans">
            Bonus Points!
          </h2>
          <div className="flex flex-row">
            <img src={coin} className="w-12 ml-20 spinner" alt="coin" />
            <img src={coin} className="w-12 spinner" alt="coin" />
          </div>
        </div>
        <div className=" flex justify-evenly items-center">
          <img src={Captain} className=" w-64" alt="" />
          <ol className="text-gray-700 text-base lg:text-xl mr-5 font-gillSans">
            <li>You received two extra coins you are doing great!</li>
            <li className="mt-2">Keep up the good work.</li>
          </ol>
        </div>
        <img
          src={confettiGif}
          alt="confetti"
          className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 pointer-events-none"
          style={{ width: "60%", height: "auto" }}
        />
      </div>
    </>
  );
};

export default BonusPoints;
