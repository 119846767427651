import React, { useState, useEffect } from "react";
import axios from "axios";
import fallingOffBike from "../../assets/videos/Falling off a bike.mp4";
import BodyMap from "../BodyMapNew";
import painScale0 from "../../assets/pain scale/0.png";
import painScale2 from "../../assets/pain scale/2.png";
import painScale4 from "../../assets/pain scale/4.png";
import painScale6 from "../../assets/pain scale/6.png";
import painScale8 from "../../assets/pain scale/8.png";
import painScale10 from "../../assets/pain scale/10.png";
import confettiGif from "../../assets/videos/Celebrations/confetti.gif";

const Level4 = ({ onQuizSuccess, onIncorrectAttempt, carrier, setCorrect1 }) => {
  const [showQuiz, setShowQuiz] = useState(false);
  const [currentQuestion, setCurrentQuestion] = useState(1);
  const [result, setResult] = useState(null);
  const [isFading, setIsFading] = useState(false);
  const [quizCompleted, setQuizCompleted] = useState(false);
  const [incorrectAttempts, setIncorrectAttempts] = useState(0); // New state for incorrect attempts
  console.log("Hi" + carrier);
  const painScales = [
    painScale0,
    painScale2,
    painScale4,
    painScale6,
    painScale8,
    painScale10,
  ];

  useEffect(() => {
    if (quizCompleted) {
      handleSubmit();
    }
  }, [quizCompleted]);

  const handleQuizAttempt = () => {
    setShowQuiz(true);
  };

  const handleSubmit = async () => {
    let userId = carrier?.userId;

    try {
      const payload = [
        {
          userId: userId,
          missionId: "M3",
          pageNum: 30,
          activityId: "M3Annie's_Pain1",
          activityValue: JSON.stringify({
            question: "Annie's Pain 1",
            result: result,
          }),
        },
      ];
      const response = await axios.post(
        "https://api.feelingbetterasd.com/mission/activity/saveMissionActivityDetails",
        payload
      );
      console.log("Response:", response.data);

      onQuizSuccess("4");
      console.log("onQuizSuccess called with '4'");
      resetQuizState();
    } catch (error) {
      console.error("Error saving mission activity:", error);
    }
  };

  const resetQuizState = () => {
    setShowQuiz(false);
    setCurrentQuestion(1);
    setResult(null);
    setIsFading(false);
    setQuizCompleted(false);
    setIncorrectAttempts(0); // Reset incorrect attempts
  };

  const handleAreaClick = (areaTitle) => {
    if (areaTitle === "Legs") {
      setResult("correct");
      setIsFading(true);
      setTimeout(() => {
        setIsFading(false);
        setCurrentQuestion(2);
      }, 1000);
      setCorrect1(true);
    } else {
      setResult("incorrect");
      setIncorrectAttempts(incorrectAttempts + 1); // Increment incorrect attempts
      if (incorrectAttempts + 1 >= 3) {
        setQuizCompleted(true); // End quiz after 3 incorrect attempts
      }
      setTimeout(() => setResult(null), 3000);
    }
  };

  const handleImageClick = (index) => {
    if (index === 2) {
      setCurrentQuestion(3);
      setIsFading(true);
      setQuizCompleted(true);
    } else {
      setResult("incorrect");
      setIncorrectAttempts(incorrectAttempts + 1); // Increment incorrect attempts
      if (incorrectAttempts + 1 >= 3) {
        setQuizCompleted(true); // End quiz after 3 incorrect attempts
      }
    }
  };

  return (
    <div className="flex flex-col items-center justify-center gap-5 fadeIn -mt-20">
      {!showQuiz ? (
        <div className="mt-10">         
          <div className="lg:text-xl text-gray-700 text-base font-gillSans">
          <h2 className="text-xl font-bold text-center text-[#F79D84] md:text-2xl md:mt-2 lg:text-4xl font-gillSans">
            Task 1
          </h2>
            <p className="text-center">Watch this video very carefully!</p>
            <div className="flex flex-row mt-10 justify-center items-center mr-6 gap-12 lg:pr-8">
              <img src={painScale4} alt="level-4-pain" className="w-32" />
              <video width="450" controls>
                <source src={fallingOffBike} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            </div>
            <div className="flex justify-center">
              <button
                onClick={handleQuizAttempt}
                className="relative inline-flex items-center justify-center p-4 px-6 py-3 overflow-hidden font-medium text-[#F79D84] transition duration-300 ease-out border-2 border-black rounded-full shadow-md group mt-8"
              >
                <span className="absolute inset-0 flex items-center justify-center w-full h-full text-white duration-300 -translate-x-full bg-[#3FA7D6] group-hover:translate-x-0 ease">
                  <svg
                    className="w-6 h-6"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M14 5l7 7m0 0l-7 7m7-7H3"
                    ></path>
                  </svg>
                </span>
                <span className="absolute flex items-center justify-center w-full h-full text-gray-700 transition-all duration-300 transform group-hover:translate-x-full ease">
                  Attempt Quiz!
                </span>
                <span className="relative invisible">Attempt Quiz</span>
              </button>
            </div>
          </div>
        </div>
      ) : (
        <div className="mt-10">
          <h2
            className={`text-xl font-bold mb-2 text-center text-[#F79D84] md:text-2xl md:mt-2 lg:text-4xl transition-opacity duration-1000 font-gillSans ${
              isFading ? "opacity-0" : "opacity-100"
            }`}
          >
            Annie's Pain
          </h2>
          <div
            className={`flex justify-around text-gray-700 text-base transition-opacity font-gillSans duration-1000 ${
              isFading ? "opacity-0" : "opacity-100"
            }`}
          >
            {currentQuestion === 1 && (
              <div className="flex flex-row items-center justify-around gap-11">
                <p className="text-xl">
                  What Body Part Hurts Annie?{" "}
                  {result === "incorrect" && (
                    <div className="text-center text-red-500 text-xl shake font-gillSans">
                      Incorrect Answer
                    </div>
                  )}
                </p>
                <BodyMap onAreaClick={handleAreaClick} />
              </div>
            )}

            {currentQuestion === 2 && (
              <div className="flex flex-col items-center">
                <p className="text-xl">How was she feeling?</p>
                <div className="flex mt-10 justify-around items-center gap-5 max-w-3xl">
                  {painScales.map((src, index) => (
                    <div className="flex items-center flex-col" key={index}>
                      <img
                        src={src}
                        alt={`pain-scale-${index}`}
                        className="hover:scale-110 transform transition duration-300 ease-in-out cursor-pointer"
                        onClick={() => handleImageClick(index)}
                      />
                    </div>
                  ))}
                </div>
                {result === "incorrect" && (
                  <div className="text-center mt-5 text-red-500 text-xl shake font-gillSans">
                    Incorrect Answer
                  </div>
                )}
              </div>
            )}

            {quizCompleted && (
              <div className="text-center mt-24 text-gray-700 text-2xl animate-zoomIn font-gillSans">
                {incorrectAttempts >= 3 ? (
                  <p>You have exceeded the maximum number of attempts.</p>
                ) : (
                  <p>Correct Answers, <br /> Keep up the hard work Striker!</p>
                )}
                <img
                  src={confettiGif}
                  alt="confetti"
                  className="w-80 mx-auto mt-10"
                />
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default Level4;
