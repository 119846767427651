import React from "react";
import painScale from "../../assets/pain scale/Pain scale.png";
import cptCharlie from "../../assets/cptCharlie.png";
import coin from "../../assets/Icons/Coin.png";
import coins from "../../assets/audios/coins.mp3";

const PSConclusion2 = () => {
  const audioPlay = () => {
    const audio = new Audio(coins);
    audio.play();
  };
  audioPlay();

  return (
    <div className="w-80 h-72 text-sm max-w-4xl bg-transparent md:w-[60%] lg:h-96 xl:h-1/2 xl:pr-7 fadeIn">
      <div className="flex flex-row items-center justify-center gap-2">
        <h2 className="text-xl font-bold mb-2 text-center text-[#F79D84] md:text-xl md:mt-2 lg:text-4xl font-gillSans">
          Using the pain scale
        </h2>
        <img src={coin} className="w-12 ml-20 heartbeat" alt="coin" />
      </div>
      <div className="flex flex-row">
        <div>
          <img src={cptCharlie} className=" w-80" alt="" />
        </div>
        <div className="text-gray-700 text-base font-gillSans">
          <ol className="lg:text-xl mr-10">
            <li className="mt-4">
              A pain scale is different pictures of faces that show how much
              something hurts.
            </li>
            <li className="mt-4">
              It's important that your mum/dad or teacher understand how much
              something hurts you, so they can help you feel better.
            </li>
            <li className="mt-4">
              Using a pain scale can help make you feel better.
            </li>
            <div className="flex flex-row justify-around mt-4">
              <img src={painScale} alt="painScale" className=" w-96 mt-6" />
            </div>
          </ol>
        </div>
      </div>
    </div>
  );
};

export default PSConclusion2;
