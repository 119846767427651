import React from "react";
import badge from "../../assets/Badges/bodyPartsExpert.png";
import stomachPain from "../../assets/pain scale/Point to stomach.png";
import coin from "../../assets/Icons/Coin.png";
import coins from "../../assets/audios/coins.mp3";

const PartExpert = () => {
  const audioPlay = () => {
    const audio = new Audio(coins);
    audio.play();
  };
  audioPlay();

  return (
    <div className="w-80 h-72 text-sm max-w-4xl bg-transparent md:w-[60%] lg:h-96 xl:h-1/2 xl:pr-7 fadeIn">
      <div className="flex flex-row items-center justify-center gap-2">
        <h2 className="text-xl font-bold mb-2 text-center text-[#F79D84] md:text-2xl md:mt-2 lg:text-4xl font-gillSans flex items-center justify-center">
          Pointing to the Body Part That Hurts
        <img src={coin} className="w-12 ml-2 heartbeat" alt="coin" />
        </h2>
      </div>
      <div className="text-gray-700 text-base fadeIn font-gillSans">
        <p className="mt-2 lg:text-xl">
          When you received your badge 'Body Parts Expert' in Mission 1 you
          learned that when a body part hurts you can point to the body part.
        </p>
        <ol className="mt-2 flex flex-row justify-around flex-wrap">
          <li className="flex items-center justify-evenly mt-1 fadeIn">
            <img src={badge} className="w-44 " alt="bodyMap" />
          </li>
          <li className="flex flex-col items-center justify-evenly mt-6 fadeIn">
            <img src={stomachPain} className="w-44 " alt="stomachPain" />
            <span className="lg:text-xl text-center">
              Annie's tummy hurts. <br /> She is pointing to her tummy to show
              that it hurts.
            </span>
          </li>
        </ol>
      </div>
    </div>
  );
};

export default PartExpert;
