import React, { useRef, useState } from "react";
import mateyMorgan from "../../assets/Characters/MateyMorgan.png";
import mateyMorganAudio from "../../assets/audios/mateyMorganAudio.mp3";

const MateyMorgan = ({ onNext }) => {
  const [ready, setReady] = useState(false);
  const audioRef = useRef(null);

  const handleStart = () => {
    setReady(true);
    onNext();
  };

  const handlePlayClick = () => {
    if (audioRef.current) {
      audioRef.current.play();
    }
  };

  return (
    <div className="w-80 h-72 text-sm max-w-4xl bg-transparent md:w-[60%] lg:h-96 xl:h-1/2 xl:pr-7 fadeIn -mt-10">
      <h2 className="text-4xl font-bold mb-2 text-left text-[#F79D84] md:text-2xl lg:text-4xl font-gillSans">
        Matey Morgan
      </h2>
      <div className="flex flex-row items-center justify-between">
        <div className="flex flex-col justify-center gap-4">
          <p className="lg:text-xl text-left">
            Have a listen to Matey Morgan talk about the importance of using a
            pain scale.
          </p>
          <button
            onClick={handleStart}
            className="px-6 py-2 rounded-md bg-green-600 text-white font-bold transition duration-200 hover:bg-green-500 border-2 border-transparent w-max"
          >
            Play
          </button>
        </div>
        <div className="flex justify-center items-center">
          <img
            src={mateyMorgan}
            alt="Matey Morgan"
            className="w-92 h-auto rounded-lg"
          />
        </div>
      </div>
      <audio ref={audioRef} src={mateyMorganAudio} />
    </div>
  );
};

export default MateyMorgan;
