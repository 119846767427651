import React, { useState, useEffect } from "react";
import axios from "axios";
import painScale0 from "../../assets/pain scale/0.png";
import painScale2 from "../../assets/pain scale/2.png";
import painScale4 from "../../assets/pain scale/4.png";
import painScale6 from "../../assets/pain scale/6.png";
import painScale8 from "../../assets/pain scale/8.png";
import painScale10 from "../../assets/pain scale/10.png";
import coin from "../../assets/Icons/Coin.png";
import coins from "../../assets/audios/coins.mp3";

const Activity = ({ carrier, onTryAgain, handleSpeech, submitted, setSubmitted, setTask2Completed }) => {
  const [clickedIndex, setClickedIndex] = useState(null);
  const [incorrectAttempts, setIncorrectAttempts] = useState(0); // State for incorrect attempts

  const handleTry = () => {
    setSubmitted(false);
    onTryAgain();
  };

  const audioPlay = () => {
    const audio = new Audio(coins);
    audio.play();
  };

  if (clickedIndex === 5) {
    audioPlay();
  }
  if(incorrectAttempts >= 3 ) {
    setTask2Completed(false);
  }
  else{
    setTask2Completed(true);
  }
  const handleImageClick = async (index) => {
    if (index === 5) {
      setClickedIndex(5); // If correct answer
      setSubmitted(true);
      
      sessionStorage.setItem("activityCompleted", "true");
    } else {
      setClickedIndex(-1); // If incorrect answer
      setSubmitted(false);
      setIncorrectAttempts((prev) => prev + 1); // Increment incorrect attempts
    }
    let userId = carrier.userId;
    // Hit the API to save activity details
    try {
      const payload = [
        {
          userId: userId, // Assuming userId is part of carrier
          missionId: "M3", // Example mission ID
          pageNum: 26, // Example page number
          activityId: "M3PainQuiz", // Example activity ID
          activityValue: JSON.stringify({ selectedOption: index }), // Save selected option
        },
      ];
      const response = await axios.post(
        "https://api.feelingbetterasd.com/mission/activity/saveMissionActivityDetails",
        payload
      );
      console.log("Response:", response.data);
      setSubmitted(true);
    } catch (error) {
      console.error("Error saving mission activity:", error);
    }
  };

  const painScales = [
    painScale0,
    painScale2,
    painScale4,
    painScale6,
    painScale8,
    painScale10,
  ];

  useEffect(() => {
    if (submitted) {
      if (clickedIndex === 5) {
        handleSpeech(28.2);  // Play correct answer audio when correct
      } else if (clickedIndex !== 5) {
        handleSpeech(28.3);  // Play incorrect answer audio when incorrect
      }
    }
  }, [submitted, clickedIndex, handleSpeech]);

  if (submitted) {
    return clickedIndex === 5 ? (
      <div className="flex max-w-lg flex-col bounceIn font-gillSans">
        <div className="flex flex-row items-center justify-center gap-2">
          <h2 className="text-5xl font-bold mb-2 text-center text-green-600 md:text-2xl md:mt-2 lg:text-4xl font-gillSans">
            Correct Answer!
          </h2>
          <img src={coin} className="w-12 ml-20 heartbeat" alt="coin" />
        </div>
        <ol className="text-xl mt-3">
          <li>Excellent Job Striker {carrier?.firstName}! </li>
          <li className="mt-3">
            That's correct the Red face shows the most pain.
          </li>
          <li className="mt-3">
            <div className="flex items-center flex-col">
              <img src={painScale10} alt="pain-scale" className="w-20" />
              <span className="mt-2 text-center font-gillSans">
                10 <br />
                Hurts worst
              </span>
            </div>
          </li>
        </ol>
      </div>
    ) : (
      <div className="flex max-w-lg flex-col shake font-gillSans">
        <h2 className="text-5xl mt-5 text-center text-red-600">
          Incorrect Answer!
        </h2>
        <ol className="text-xl mt-3">
          <li>Let's Try Again.</li>
          <li className="mt-3">
            Let's listen to the pain scale again and tell Captain Charlie what
            face shows the most pain.
          </li>
          <li className="mt-3">
            Remember if you need help, click the help button at the top of the
            page.
          </li>
          
        </ol>
        <div className="flex justify-center">
          <button
            className="px-4 py-2 mt-4 w-32 bg-red-800 text-white rounded-md hover:bg-red-600"
            onClick={handleTry}
          >
            Try Again
          </button>
        </div>
        {incorrectAttempts>2 ? (<p className="text-xl mt-4">correct answer is Red Face</p>): (<></>)}

      </div>
    );
  }

  return (
    <div className="w-80 h-72 text-sm max-w-4xl bg-transparent md:w-[60%] lg:h-96 xl:h-1/2 xl:pr-7 fadeIn">
      <h2 className="text-xl font-bold mb-2 text-center text-[#F79D84] md:text-2xl md:mt-2 lg:text-4xl font-gillSans">
        Task
      </h2>
      <div className="text-gray-700 text-base font-gillSans">
        <ol className="text-xl">
          <li className="mt-1">
            Can you guess which faces show the{" "}
            <span className="font-semibold"> most</span> pain?
          </li>
          <li className="mt-4">Select the face-</li>
          <div className="flex mt-10 justify-around items-center gap-5 max-w-3xl">
            {painScales.map((src, index) => (
              <div className="flex items-center flex-col" key={index}>
                <img
                  src={src}
                  alt={`pain-scale-${index}`}
                  className="hover:scale-110 transform transition duration-300 ease-in-out cursor-pointer"
                  onClick={() => handleImageClick(index)}
                />
              </div>
            ))}
          </div>
        </ol>
        {submitted && (
          <div className="mt-5 text-center">Submitted successfully!</div>
        )}
      </div>
    </div>
  );
};

export default Activity;
