import React, { useState } from "react";
import stomachPain from "../../assets/videos/Stomach Pain.mp4";

const PirateQuiz = ({ carrier }) => {
  const [Ready, setReady] = useState(false);
  const handleReady = () => {
    setReady(!Ready);
  };
  return (
    <div className="w-80 h-72 text-sm max-w-4xl bg-transparent md:w-[60%] lg:h-96 xl:h-1/2 xl:pr-7 fadeIn">
      <h2 className="text-xl font-bold mb-2 text-center text-[#F79D84] md:text-2xl md:mt-2 lg:text-4xl font-gillSans">
        Pirate Quiz
      </h2>
      <div className="text-gray-700 text-base text-center font-gillSans">
        <ol className="mt-5 ">
          <li className="mt-3 ml-4 text-justify">
            <p className="text-xl">
              {carrier?.firstName} your pirate quiz is to watch a video and then
              answer 1 very important question.
            </p>
          </li>
          <li className="text-xl">Are you Ready?</li>

          {Ready ? (
            <>
              <div className="  flex mt-6 justify-center pr-6 gap-5 lg:pr-8">
                <video width="450" controls>
                  <source src={stomachPain} type="video/mp4" />
                  Your browser does not support the video tag.
                </video>
              </div>
            </>
          ) : (
            <button
              onClick={handleReady}
              className="px-8 py-2 rounded-md bg-green-500 text-white font-bold transition duration-200 hover:bg-[#ebdbc4] hover:text-[#374151] border-2 border-transparent hover:border-black mt-10"
            >
              Yes Let's Go
            </button>
          )}
        </ol>
      </div>
    </div>
  );
};

export default PirateQuiz;
