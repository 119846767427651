import React from "react";
import Captain from "../assets/cptCharlie.png";

const SeeYou = ({ rank, carrier }) => {
  return (
    <div className=" flex flex-col text-center items-center fadeIn">
      <img src={Captain} className=" w-56" alt="Cpt. Charlie" />
      <div>
        <h2 className=" text-xl font-bold mb-2 text-center text-[#F79D84] md:text-2xl md:mt-2 lg:text-4xl font-gillSans">
          See You Next Week {rank} {carrier?.firstName}
        </h2>
        <p className="text-xl text-gray-700 font-gillSans">
          Come back next week to learn more from Captain Charlie
        </p>
      </div>
    </div>
  );
};

export default SeeYou;
