import React from "react";
import footBall from "../../assets/videos/Football.mp4";

const Activity2 = () => {
  return (
    <div className="w-80 h-72 text-sm max-w-4xl bg-transparent md:w-[60%] lg:h-96 xl:h-1/2 xl:pr-7 fadeIn">
      <h2 className="text-xl font-bold mb-2 text-center text-[#F79D84] md:text-2xl md:mt-2 lg:text-4xl font-gillSans">
        Task
      </h2>
      <div className="text-gray-700 text-base font-gillSans">
        <ol className="lg:text-xl">
          <li className="mt-1">Captain Charlie has set you a task.</li>
          <li className="mt-1">
            Watch this video and after you have a special task to complete
          </li>
          <div className="  flex mt-5 justify-center mr-6 gap-5 lg:pr-8">
            <video width="450" controls>
              <source src={footBall} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          </div>
        </ol>
      </div>
    </div>
  );
};

export default Activity2;
