import React, { useState, useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import homeButton from "../../assets/homeBtn.png";
import School from "../School";
import Ready from "../Ready";
import sectionsBG from "../../assets/sectionsBG.png";
import ProgressBar from "../ProgressBar";
import FirstThenBoard from "../FirstThenBoard";
import { useLocation } from "react-router-dom";
import helpAudio from "../../assets/audios/INeedHelp.mp3";
import Mission4Intro from "./Mission4Intro";
import MissionPath from "./MissionPath";
import WelcomeBack4 from "./WelcomeBack4";
import MissionThisWeek4 from "./MissionThisWeek4";
import PainThisWeek from "./PainThisWeek";
import MissionPath1 from "./MissionPath1";
import PainScale from "./PainScale";
import QuickFire from "./QuickFire";
import Impressive from "./Impressive";
import MissionPath2 from "./MissionPath2";
import LearningToCommunicate from "./LearningToCommunicate";
import ActivityTime from "./ActivityTime";
import MateyMorgan from "./MateyMorgan";
import MateyMorgan2 from "./MateyMorgan2";
import Quiz from "./Quiz";
import BonusPoints from "./BonusPoints";
import PSAdventure from "./PSAdventure";
import Polly from "./Polly";
import YourTurn from "./YourTurn";
import Adventure from "./Adventure";
import AnniePain from "./AnniePain";
import AnnieQuiz from "./AnnieQuiz";
import BonusQuestion from "./BonusQuestion";
import MorganPain from "./MorganPain";
import MorganQuestion from "./MorganQuestion";
import MorganQuiz from "./MorganQuiz";
import PSConclusion from "./PSConclusion";
import PSConclusion2 from "./PSConclusion2";
import MissionPath3 from "./MissionPath3";
import PirateQuiz from "./PirateQuiz";
import PiratePainQuiz from "./PiratePainQuiz";
import Badge4 from "./Badge4";
import MissionPath4 from "./MissionPath4";
import Mission4Complete from "./Mission4Complete";
import EndMission4 from "./EndMission4";
import MissionPath5 from "./MissionPath5";
import SeeYou4 from "./SeeYou4";
import BreakTimer from "../BreakTImer";
import EndMission4Continued from "../mission4/EndMission4Continued";
import RecapTask from "./RecapTask";
import speaker from "../../assets/speaker.png";
import pg2 from "../../assets/audios/speech/Mission4/pg2.mp3";
import pg3 from "../../assets/audios/speech/Mission4/pg3.mp3";
import pg4 from "../../assets/audios/speech/Mission4/pg4.mp3";
import pg5 from "../../assets/audios/speech/Mission4/pg5.mp3";
import pg6 from "../../assets/audios/speech/Mission4/pg6.mp3";
import pg7 from "../../assets/audios/speech/Mission4/pg7.mp3";
import pg8 from "../../assets/audios/speech/Mission4/pg8.mp3";
import pg9 from "../../assets/audios/speech/Mission4/pg9.mp3";
import pg10 from "../../assets/audios/speech/Mission4/pg10.mp3";
import pg11 from "../../assets/audios/speech/Mission4/pg11.mp3";
import pg12 from "../../assets/audios/speech/Mission4/pg12.mp3";
import pg13 from "../../assets/audios/speech/Mission4/pg13.mp3";
import pg14 from "../../assets/audios/speech/Mission4/pg14.mp3";
import pg15 from "../../assets/audios/speech/Mission4/pg15.mp3";
import pg16 from "../../assets/audios/speech/Mission4/pg16.mp3";
import pg17 from "../../assets/audios/speech/Mission4/pg17.mp3";
import pg18 from "../../assets/audios/speech/Mission4/pg18.mp3";
import pg19 from "../../assets/audios/speech/Mission4/pg19.mp3";
// import pg20 from "../../assets/audios/speech/Mission4/pg20.mp3";
import pg21 from "../../assets/audios/speech/Mission4/pg21.mp3";
import pg22 from "../../assets/audios/speech/Mission4/pg22.mp3";
import pg23 from "../../assets/audios/speech/Mission4/pg23.mp3";
import pg24 from "../../assets/audios/speech/Mission4/pg24.mp3";
import pg25 from "../../assets/audios/speech/Mission4/pg25.mp3";
import pg26 from "../../assets/audios/speech/Mission4/pg26.mp3";
import pg27 from "../../assets/audios/speech/Mission4/pg27.mp3";
import pg28 from "../../assets/audios/speech/Mission4/pg28.mp3";
import pg29 from "../../assets/audios/speech/Mission4/pg29.mp3";
// import pg30 from "../../assets/audios/speech/Mission4/pg30.mp3";
import pg31 from "../../assets/audios/speech/Mission4/pg31.mp3";
import pg32 from "../../assets/audios/speech/Mission4/pg32.mp3";
import pg33 from "../../assets/audios/speech/Mission4/pg33.mp3";
import pg34 from "../../assets/audios/speech/Mission4/pg34.mp3";
import pg35 from "../../assets/audios/speech/Mission4/pg35.mp3";
import pg36 from "../../assets/audios/speech/Mission4/pg36.mp3";
import pg37 from "../../assets/audios/speech/Mission4/pg37.mp3";
import pg38 from "../../assets/audios/speech/Mission4/pg38.mp3";
import pg39 from "../../assets/audios/speech/Mission4/pg39.mp3";
import pg40 from "../../assets/audios/speech/Mission4/pg40.mp3";
import pg41 from "../../assets/audios/speech/Mission4/pg41.mp3";
import pg42 from "../../assets/audios/speech/Mission4/pg42.mp3";
import pg43 from "../../assets/audios/speech/Mission4/pg43.mp3";
import pg44 from "../../assets/audios/speech/Mission4/pg44.mp3";
// import pg45 from "../../assets/audios/speech/Mission4/pg45.mp3";
import pg46 from "../../assets/audios/speech/Mission4/pg46.mp3";
import pg47 from "../../assets/audios/speech/Mission4/pg47.mp3";
import pg48 from "../../assets/audios/speech/Mission4/pg48.mp3";
import pg49 from "../../assets/audios/speech/Mission4/pg49.mp3";
import pg50 from "../../assets/audios/speech/Mission4/pg50.mp3";
import pg51 from "../../assets/audios/speech/Mission4/pg51.mp3";
import pg52 from "../../assets/audios/speech/Mission4/pg52.mp3";
import pg53 from "../../assets/audios/speech/Mission4/pg53.mp3";
import pg54 from "../../assets/audios/speech/Mission4/pg54.mp3";
import pg55 from "../../assets/audios/speech/Mission4/pg55.mp3";
import pg56 from "../../assets/audios/speech/Mission4/pg56.mp3";
import pg57 from "../../assets/audios/speech/Mission4/pg57.mp3";
import pg58 from "../../assets/audios/speech/Mission4/pg58.mp3";
import pg59 from "../../assets/audios/speech/Mission4/pg59.mp3";

import axios from "axios";

const Mission4 = () => {
  // Initialize the section state using useState
  const [section, setSection] = useState(1);
  const [isBreak, setIsBreak] = useState(false);
  const audioRef = useRef(null);
  const [submitted, setSubmitted] = useState(false);

  const [inputValue, setInputValue] = useState(
    sessionStorage.getItem("firstThenInput") || ""
  ); // Initialize with session storage value
  // useNavigate init.
  const navigate = useNavigate();

  // Decrease the section value when "Previous" button is clicked
  const handlePrevious = () => {
    setSection(section - 1);
    console.log(section - 1);
  };

  // Determine if the "Next" button should be disabled
  const handleNext = () => {
    if (
      (section === 6 && !sessionStorage.getItem("schoolQuizCompleted")) ||
      (section === 7 && !sessionStorage.getItem("pain4Completed")) ||
      (section === 10 && !sessionStorage.getItem("recapQuizCompleted")) ||
      (section === 12 && !sessionStorage.getItem("quickFireCompleted")) ||
      (section === 19 && !sessionStorage.getItem("taskTime4Completed")) ||
      (section === 22 && !sessionStorage.getItem("pollyCompleted")) ||
      (section === 23 && !sessionStorage.getItem("yourTurnCompleted")) ||
      (section === 27 && !sessionStorage.getItem("annieQuizCompleted")) ||
      (section === 28 && !sessionStorage.getItem("bonusQuizCompleted")) ||
      (section === 31 && !sessionStorage.getItem("morganQuizCompleted")) ||
      (section === 36 && !sessionStorage.getItem("pQ4Completed"))
    ) {
      toast.error("Please complete the quiz before proceeding.");
      return;
    }

    if (isLastPage) {
      navigate("/map", { state: { carrier } });
    } else {
      setSection(section + 1);
    }
    console.log(section);
  };

  const isPreviousDisabled = section === 1;
  // const isNextDisabled = section === 44;
  const isLastPage = section === 44;

  const handleInputChange = (value) => {
    setInputValue(value);
    sessionStorage.setItem("firstThenInput", value); // Save to session storage
  };
  const totalSections = 44;
  const location = useLocation();
  const carrier = location.state?.carrier;
  // Function to handle home button click
  const handleHomeClick = () => {
    // Navigate back to the previous screen
    navigate(-1);
  };

  // Function to show toast notification
  const handleBreakClick = () => {
    toast.success("You can take a break now, your progress has been saved!");
    setIsBreak(true);
  };

  useEffect(() => {
    return () => {
      if (audioRef.current) {
        audioRef.current.pause();
        audioRef.current = null; // Clean up the audio reference
      }
    };
  }, [section]);
  // Function to play help audio
  const handleHelpClick = () => {
    const audio = new Audio(helpAudio);
    audio.play();
  };
  const userId = carrier?.userId;
   const saveStarsCollected = async () => {
    try {
      const response = await axios.post(
        "https://api.feelingbetterasd.com/saveStarsCollected",
        {
          userId: userId,
          missionId: 4, // Replace with the actual mission ID
          starsCollected: 20, // Replace with the actual stars collected
          totalStars: 20, // Replace with the actual total stars
        }
      );
      if (response.status === 200) {
        const { data } = response.data;
        console.log("Success", data);
      }
    } catch (error) {
      console.error("Error saving stars:", error);
    }
  };

  if (section === 44) {
    saveStarsCollected();
  }
  // Function to handle closing the break timer
  const handleBreakClose = () => {
    setIsBreak(false);
  };

  const handleTryAgain = () => {
    setSection(section - 1);
  };

  const handleTryAgain2 = () => {
    setSection(section - 2);
  };
  // Function to handle play text to speech
  const handleSpeech = () => {
    const sectionAudioMap = {
      
        2: pg2,
        3: pg3,
        4: pg4,
        5: pg5,
        6: pg6,
        7: pg7,
        8: pg8,
        9: pg9,
        10: pg10,
        11: pg13,
        12: pg14,
        13: pg17,
        14: pg18,
        15: pg19,
        16: pg9,
        17: pg21,
        18: pg22,
        19: pg23,
        20: pg26,
        21: pg27,
        22: pg28,
        23: pg32,
        24: pg33,
        25: pg34,
        26: pg35,
        27: pg36,
        28: pg39,
        29: pg42,
        30: pg43,
        31: pg44,
        32: pg47,
        33: pg48,
        34: pg49,
        35: pg50,
        36: pg51,
        37: pg52,
        38: pg53,
        39: pg54,
        40: pg55,
        41: pg56,
        42: pg57,
        43: pg58,
        44: pg59,
        // 45: pg45,
        
      
      

      // Add mappings for other sections here
    };

    const audioFile = sectionAudioMap[section];
    if (audioFile) {
      // Pause the previous audio if it's playing
      if (audioRef.current) {
        audioRef.current.pause();
      }
      // Create a new audio instance and play it
      audioRef.current = new Audio(audioFile);
      audioRef.current.play();
    }
  };

  return (
    <div className="h-screen flex flex-col justify-between pb-4 pr-3 pl-3 pt-1 bg-[#3FA7D6]">
      <ToastContainer />
      {/* Home Button */}
      <div className="flex flex-row items-center justify-between w-full">
        <img
          src={homeButton}
          alt="home-btn"
          className="cursor-pointer hover:scale-110 hover:invert"
          onClick={handleHomeClick}
        />
        <button
          className="bg-[#EE6352] text-white font-bold rounded-lg px-6 py-2 ml-4 transition duration-200 hover:bg-[#ebdbc4] hover:text-[#374151] border-2 border-transparent hover:border-[#9c6334]"
          onClick={handleHelpClick}
        >
          Help
        </button>
        <div className="flex-grow ml-0">
          <ProgressBar currentSection={section} totalSections={totalSections} />
        </div>
        <button
          className="bg-[#EE6352] text-white font-bold rounded-lg px-6 py-2 mr-4 transition duration-200 hover:bg-[#ebdbc4] hover:text-[#374151] border-2 border-transparent hover:border-[#9c6334]"
          onClick={handleBreakClick}
        >
          Break
        </button>
        <img
          src={speaker}
          alt="speaker"
          className={`hover:scale-110 ease-in-out transition duration-300 ${
            submitted ? "opacity-50 cursor-not-allowed" : ""
          }`}
          onClick={() => {
            if (!submitted) {
              handleSpeech();
            }
          }}
        />
      </div>
      {/* Render ProgressBar component */}

      <div className="sections flex-grow">
        <div
          className="h-full flex items-center justify-center relative zoomer"
          style={{
            backgroundImage: `url(${sectionsBG})`,
            backgroundPosition: "center",
            backgroundSize: "contain",
            backgroundRepeat: "no-repeat",
          }}
        >
          <div className="absolute inset-0 flex flex-col justify-center items-center pt-4 md:pt-0">
            {isBreak ? (
              <BreakTimer onClose={handleBreakClose} />
            ) : (
              console.log("test")
            )}
          </div>
          <div className=" absolute inset-0 flex flex-col justify-center items-center pt-4 md:pt-0">
            {/* Render content based on the current section */}
            {section === 1 && <Mission4Intro />}
            {section === 2 && <MissionPath />}
            {section === 3 && (
              <FirstThenBoard
                inputValue={inputValue}
                onInputChange={handleInputChange}
              />
            )}
            {section === 4 && (
              <WelcomeBack4 carrier={carrier} onNext={handleNext} />
            )}
            {section === 5 && (
              <MissionThisWeek4 onNext={handleNext} carrier={carrier} />
            )}
            {section === 6 && <School carrier={carrier} />} {/* Lock */}
            {section === 7 && <PainThisWeek carrier={carrier} />} {/* Lock */}
            {section === 8 && <MissionPath1 />}
            {section === 9 && <Ready onNext={handleNext} />}
            {section === 10 && <RecapTask carrier={carrier} />} {/* Lock */}
            {section === 11 && <PainScale />}
            {section === 12 && (
              <QuickFire carrier={carrier} onTryAgain={handleTryAgain} />
            )}
            {section === 13 && <Impressive carrier={carrier} />}
            {section === 14 && <MissionPath2 />}
            {section === 15 && <LearningToCommunicate />}
            {section === 16 && <ActivityTime onNext={handleNext} />}
            {section === 17 && <MateyMorgan onNext={handleNext} />}
            {section === 18 && <MateyMorgan2 />}
            {section === 19 && (
              <Quiz carrier={carrier} onTryAgain={handleTryAgain2} />
            )}
            {section === 20 && <BonusPoints />}
            {section === 21 && <PSAdventure />}
            {section === 22 && <Polly audio1= {pg29} audio2 = {pg31} carrier={carrier} />} {/* Lock */}

            {section === 23 && <YourTurn carrier={carrier} />} {/* Lock */}
            {section === 24 && <Impressive carrier={carrier} />}
            {section === 25 && <Adventure onNext={handleNext} />}
            {section === 26 && <AnniePain />}
            {section === 27 && (
              <AnnieQuiz carrier={carrier} onTryAgain={handleTryAgain} />
            )}
            {/* Lock */}
            {section === 28 && <BonusQuestion carrier={carrier} />} {/* Lock */}
            {section === 29 && <MorganPain />}
            {section === 30 && <MorganQuestion />}
            {section === 31 && (
              <MorganQuiz carrier={carrier} onTryAgain={handleTryAgain2} />
            )}
            {/* Lock */}
            {section === 32 && <PSConclusion carrier={carrier} />}
            {section === 33 && <PSConclusion2 />}
            {section === 34 && <MissionPath3 />}
            {section === 35 && (
              <PirateQuiz carrier={carrier} onNext={handleNext} />
            )}
            {section === 36 && <PiratePainQuiz carrier={carrier} />}
            {section === 37 && <Badge4 />}
            {section === 38 && <MissionPath4 />}
            {section === 39 && <Mission4Complete carrier={carrier} />}
            {section === 40 && <EndMission4 />}
            {section === 41 && <EndMission4Continued />}
            {section === 42 && <MissionPath5 />}
            {section === 43 && <SeeYou4 carrier={carrier} />}
            {section === 44 && (
              <FirstThenBoard
                inputValue={inputValue}
                onInputChange={handleInputChange}
              />
            )}
          </div>
        </div>
      </div>

      <div className="flex justify-around">
        {/* "Previous" button */}
        <button
          className="px-12 py-4 rounded-full bg-[#9c6334] font-bold text-white tracking-widest uppercase transform hover:scale-110 hover:bg-[#623e20] transition-colors duration-200"
          onClick={handlePrevious}
          disabled={isPreviousDisabled}
          style={{
            backgroundColor: "#3FA7D6",
            color: "#fffff",
          }}
        >
          <svg
            class="w-10 h-10 transform rotate-180"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 14 10"
          >
            <path
              stroke="currentColor"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M1 5h12m0 0L9 1m4 4L9 9"
            />
          </svg>
          <span class="sr-only">Previous</span>
        </button>

        <div className="text-center text-xs text-white mt-6">
          <span style={{ fontSize: "1rem" }}>
            © 2024 | University of Galway, Ireland
          </span>
        </div>

        {/* "Next" button */}
        <button
          className="px-12 py-4 rounded-full bg-[#9c6334] font-bold text-white tracking-widest uppercase transform hover:scale-110 hover:bg-[#623e20] transition-colors duration-200"
          onClick={handleNext}
          style={{
            backgroundColor: "#3FA7D6",
          }}
        >
          <svg
            class="w-10 h-10"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 14 10"
          >
            <path
              stroke="currentColor"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M1 5h12m0 0L9 1m4 4L9 9"
            />
          </svg>
          <span class="sr-only">Next</span>
        </button>
      </div>
    </div>
  );
};

export default Mission4;
