import React, { useEffect } from "react";
import cptCharlie from "../../assets/cptCharlie.png";
import confettiGif from "../../assets/videos/Celebrations/confetti.gif";
import celebrationSound from "../../assets/audios/Kids Cheering.mp3";

const Section5 = () => {
  useEffect(() => {
    // Play celebration audio when the component mounts
    const audio = new Audio(celebrationSound);
    audio.play();

    // Clean up the audio when the component unmounts
    return () => {
      audio.pause();
      audio.currentTime = 0;
    };
  }, []);
  return (
    <div className="w-80 h-72 text-sm max-w-4xl bg-transparent md:w-[60%] lg:h-96 xl:h-1/2 xl:pr-7 fadeIn">
      <h2 className="text-xl font-bold mb-2 text-center text-[#F79D84] md:text-2xl md:mt-2 lg:text-4xl font-gillSans">
        Excellent Job
      </h2>
      <div className="text-gray-700 text-base flex font-gillSans">
        <img
          src={cptCharlie}
          className=" lg:w-72 md:w-64 items-center"
          alt="captain-charlie"
        />
        <ol className="lg:text-xl lg:mt-10 mx-8">
          <li className="lg:mt-6">
            Thank you for telling Captain Charlie which body parts hurt you
            sometimes.
          </li>
          <li className="lg:mt-6">
            You have done a super job helping Captain Charlie this week.
          </li>
          <li className="lg:mt-6">
            In this mission you have learnt that sometimes body parts can hurt
            and how to show where it hurts on the body map.
          </li>
        </ol>
      </div>
      <img
        src={confettiGif}
        alt="confetti"
        className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 pointer-events-none"
        style={{ width: "60%", height: "100%" }}
      />
    </div>
  );
};

export default Section5;
