import React from "react";
import Captain from "../../assets/cptCharlie.png";

const Recap = ({ carrier }) => {
  return (
    <div className=" w-80 h-72 text-sm max-w-4xl bg-transparent md:w-[60%] lg:h-96 xl:h-1/2 xl:pr-7 fadeIn">
      <h2 className="text-xl font-bold mb-2 text-center text-[#F79D84] md:text-2xl md:mt-2 lg:text-4xl font-gillSans">
        What you have Learned Today
      </h2>
      <div className="text-gray-700 flex flex-row text-base font-gillSans">
        <div>
          <img src={Captain} className=" w-72" alt="" />
        </div>
        <div>
          <p className=" mt-10 lg:text-xl">
            Excellent Job Striker {carrier?.firstName}
          </p>
          <p className=" mt-10 lg:text-xl">
            You have done a super job helping Captain Charlie this week.
          </p>
          <p className=" mt-10 lg:text-xl">
            This week you learnt how to use the pain scale to show how much pain
            you feel.
          </p>
        </div>
      </div>
    </div>
  );
};

export default Recap;
