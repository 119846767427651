import React, { useEffect, useState } from "react";
import bodyPartsExpert from "../../assets/Badges/bodyPartsExpert.png";
import celebrationSound from "../../assets/audios/Kids Cheering.mp3";


const Badge = () => {
  useEffect(() => {
    // Play celebration audio when the component mounts
    const audio = new Audio(celebrationSound);
    audio.play();

    // Clean up the audio when the component unmounts
    return () => {
      audio.pause();
      audio.currentTime = 0;
    };
  }, []);
  return (
    <div className="w-80 h-72 text-sm max-w-4xl bg-transparent md:w-[60%] lg:h-96 xl:h-1/2 xl:pr-7 fadeIn">
      <h2 className="text-xl font-bold mb-2 text-center text-[#F79D84] md:text-2xl md:mt-2 lg:text-4xl font-gillSans">
        Your Special Badge
      </h2>
      <div className="text-gray-700 text-base font-gillSans">
        <ol className="text-xl mt-10 ">
          <li className="mt-6 text-center font-gillSans">
            Congratulations! <br/> Captain Charlie has awarded you a special badge
            called 'Body Parts Expert' <br/>for all your hard work in learning
            about body parts.
          </li>
          <div className="  flex mt-8 justify-center pr-6 gap-5 lg:pr-8">
            <img src={bodyPartsExpert} alt="badge" className="w-56" />
          </div>
        </ol>
      </div>
    </div>
  );
};

export default Badge;
