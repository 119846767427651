import React from "react";
import cptCharlie from "../../assets/cptCharlie.png";
import PainDiaryBoard from "../../assets/Boards/painDiaryBoard.png";
const EndMission = () => {
  return (
    <div className="w-80 h-72 text-sm max-w-4xl bg-transparent md:w-[60%] lg:h-96 xl:h-1/2 xl:pr-7 fadeIn -mt-20">
      <h2 className="text-xl font-bold mb-2 text-center text-[#F79D84] md:text-2xl md:mt-2 lg:text-4xl font-gillSans">
        Your job this week
      </h2>
      <div className="text-gray-700 flex flex-row text-base font-gillSans">
        <div>
          <p className=" mt-5 lg:text-lg">
            Captain Charlie needs you to do a special job this week.
          </p>

          <div className="">
            <p className=" mt-5 lg:text-lg">
              Every day you are asked to fill in your pain diary. To find your
              pain diary go to the 'My Pain Diary'
            </p>
            <img src={PainDiaryBoard} className="ml-14 w-20" />
          </div>
          <p className=" ml-2 lg:text-lg">
            If a body part hurts click on body part picture. If no body part
            hurt click on the red button.
          </p>
        </div>
        <div>
          <img src={cptCharlie} alt="cptCharlie" className=" w-9/12" />
        </div>
      </div>
    </div>
  );
};

export default EndMission;
