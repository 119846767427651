import React, { useState, useEffect, useCallback } from "react";
import headImage from "../../assets/head.png";
import stomachImage from "../../assets/stomach.png";
import mouthImage from "../../assets/mouth.png";
import armImage from "../../assets/arm.png";
import footImage from "../../assets/Body Parts/legs.png";
import headAudio from "../../assets/audios/Head.mp3";
import stomachAudio from "../../assets/audios/Tummy.mp3";
import mouthAudio from "../../assets/audios/Mouth.mp3";
import armAudio from "../../assets/audios/Arm.mp3";
import footAudio from "../../assets/audios/Legs.mp3";
import HeadSection from "./HeadSection";
import StomachSection from "./StomachSection";
import MouthSection from "./MouthSection";
import ArmSection from "./ArmSection";
import FootSection from "./FootSection";

const Section3 = () => {
  const [audioQueue, setAudioQueue] = useState([]);
  const [isPlaying, setIsPlaying] = useState(false);
  const [selectedPart, setSelectedPart] = useState(null);
  const [completedParts, setCompletedParts] = useState({
    head: sessionStorage.getItem("completed_head") === "true",
    stomach: sessionStorage.getItem("completed_stomach") === "true",
    mouth: sessionStorage.getItem("completed_mouth") === "true",
    arm: sessionStorage.getItem("completed_arm") === "true",
    foot: sessionStorage.getItem("completed_foot") === "true",
  });

  const handleMouseClick = (part, audioPath) => {
    setSelectedPart(part);
    setAudioQueue((queue) => [...queue, audioPath]);
  };

  const playNextAudio = useCallback(() => {
    if (audioQueue.length === 0) {
      setIsPlaying(false);
      return;
    }

    const nextAudioPath = audioQueue[0];
    const nextAudio = new Audio(nextAudioPath);

    nextAudio.play();
    setAudioQueue((queue) => queue.slice(1));

    nextAudio.onended = () => {
      setIsPlaying(false);
    };
  }, [audioQueue]);

  useEffect(() => {
    if (!isPlaying && audioQueue.length > 0) {
      setIsPlaying(true);
      playNextAudio();
    }
  }, [audioQueue, isPlaying, playNextAudio]);

  const handleQuizSuccess = (part) => {
    setCompletedParts((prev) => {
      const updatedParts = { ...prev, [part]: true };
      sessionStorage.setItem(`completed_${part}`, "true");
      return updatedParts;
    });
    setSelectedPart(null);
  };

  const renderComponent = () => {
    switch (selectedPart) {
      case "head":
        return <HeadSection onQuizSuccess={() => handleQuizSuccess("head")} />;
      case "stomach":
        return (
          <StomachSection onQuizSuccess={() => handleQuizSuccess("stomach")} />
        );
      case "mouth":
        return (
          <MouthSection onQuizSuccess={() => handleQuizSuccess("mouth")} />
        );
      case "arm":
        return <ArmSection onQuizSuccess={() => handleQuizSuccess("arm")} />;
      case "foot":
        return <FootSection onQuizSuccess={() => handleQuizSuccess("foot")} />;
      default:
        return null;
    }
  };

  const allPartsCompleted =
    completedParts.head &&
    completedParts.stomach &&
    completedParts.mouth &&
    completedParts.arm &&
    completedParts.foot;

  if (allPartsCompleted) {
    sessionStorage.setItem("allPartsCompleted", "true");
  }

  return (
    <div className="w-80 h-72 text-sm max-w-4xl bg-transparent md:w-[60%] lg:h-96 xl:h-1/2 xl:pr-7 fadeIn">
      {selectedPart ? (
        renderComponent()
      ) : (
        <div className="text-gray-700 text-base font-gillSans">
          <h2 className="text-xl font-bold mb-2 text-center text-[#F79D84] md:text-2xl md:mt-2 lg:text-4xl font-gillSans">
            Pain In Body Parts
          </h2>
          <ol className="text-xl">
            <li className="mt-4 mb-4">
              Your task is to learn more about body parts that can hurt.
            </li>
            <li>
              Click into each of the pictures below to learn about body parts
              that hurt:
            </li>
          </ol>

          {/* IMAGES */}
          <div className="flex pt-10 justify-between pr-6 gap-5 lg:pr-8">
            <div
              className={`flex items-center flex-col cursor-pointer ${
                completedParts.head ? "grayscale" : ""
              }`}
              onClick={() => handleMouseClick("head", headAudio)}
            >
              <img
                src={headImage}
                alt="head"
                className="hover:scale-110 transform transition duration-300 ease-in-out"
              />
              <span className="mt-2 font-gillSans lg:text-lg">Head</span>
            </div>
            <div
              className={`flex items-center flex-col cursor-pointer ${
                completedParts.stomach ? "grayscale" : ""
              }`}
              onClick={() => handleMouseClick("stomach", stomachAudio)}
            >
              <img
                src={stomachImage}
                alt="stomach"
                className="hover:scale-110 transform transition duration-300 ease-in-out"
              />
              <span className="mt-2 font-gillSans lg:text-lg">Tummy</span>
            </div>
            <div
              className={`flex items-center flex-col cursor-pointer ${
                completedParts.mouth ? "grayscale" : ""
              }`}
              onClick={() => handleMouseClick("mouth", mouthAudio)}
            >
              <img
                src={mouthImage}
                alt="mouth"
                className="hover:scale-110 transform transition duration-300 ease-in-out"
              />
              <span className="mt-2 font-gillSans lg:text-lg">Mouth</span>
            </div>
            <div
              className={`flex items-center flex-col cursor-pointer ${
                completedParts.arm ? "grayscale" : ""
              }`}
              onClick={() => handleMouseClick("arm", armAudio)}
            >
              <img
                src={armImage}
                alt="arm"
                className="hover:scale-110 transform transition duration-300 ease-in-out"
              />
              <span className="mt-2 font-gillSans lg:text-lg">Arm</span>
            </div>
            <div
              className={`flex items-center flex-col cursor-pointer ${
                completedParts.foot ? "grayscale" : ""
              }`}
              onClick={() => handleMouseClick("foot", footAudio)}
            >
              <img
                src={footImage}
                alt="foot"
                className="hover:scale-110 transform transition duration-300 ease-in-out"
              />
              <span className="mt-2 font-gillSans lg:text-lg">Legs</span>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Section3;
