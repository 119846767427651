import React, { useState } from "react";
import BodyMap from "../BodyMapNew";
import axios from "axios";
import coin from "../../assets/Icons/Coin.png";
import coins from "../../assets/audios/coins.mp3";

const Section4 = (props) => {
  const [clickedAreas, setClickedAreas] = useState([]);
  const [submitted, setSubmitted] = useState(false);

  const audioPlay = () => {
    // Play celebration audio when the component mounts
    const audio = new Audio(coins);
    audio.play();
  };

  // Function to update clicked areas
  const handleAreaClick = (area) => {
    // Check if the clicked area already exists in clickedAreas
    if (clickedAreas.includes(area)) {
      setClickedAreas((prevClickedAreas) =>
        prevClickedAreas.filter((clickedArea) => clickedArea !== area)
      );
    } else {
      setClickedAreas((prevClickedAreas) => [...prevClickedAreas, area]);
    }
  };

  const handleSubmit = async () => {
    setSubmitted(true);
    audioPlay();

    // Hit the API to save mission activity.
    try {
      const payload = [
        {
          userId: props.carrier.userId,
          missionId: "M2",
          pageNum: 27,
          activityId: "M2PirateQuiz",
          activityValue: JSON.stringify(clickedAreas),
        },
      ];
      const response = await axios.post(
        "https://api.feelingbetterasd.com/mission/activity/saveMissionActivityDetails",
        payload
      );
      console.log("Response:", response.data);
    } catch (error) {
      console.error("Error saving mission activity:", error);
    }
    sessionStorage.setItem("PQ2Completed", "true");
  };

  // Determine the number of columns based on the length of clickedAreas
  const numColumns = clickedAreas.length > 10 ? 2 : 1;

  return (
    <div className="w-80 h-72 text-sm max-w-4xl bg-transparent md:w-[60%] lg:h-96 xl:h-1/2 xl:pr-7 fadeIn -mt-20">
      <div className="flex flex-row items-center justify-center gap-2">
        <h2 className="text-xl font-bold mb-2 text-center text-[#F79D84] md:text-2xl md:mt-2 lg:text-4xl font-gillSans flex items-center justify-center">
          Pirate Quiz- Now it's your turn
          <img src={coin} className="w-12 ml-2 heartbeat" alt="coin" />
        </h2>
      </div>
      <div className="text-gray-700 text-base flex justify-evenly font-gillSans">
        <ol className="lg:text-xl lg:mt-3 text-base">
          <li className="lg:mt-8">
            {props.carrier.firstName} can you pick which body parts hurt you
            sometimes?
          </li>
          <li className="lg:mt-8">
            Touch the body map below to show where hurts you.
          </li>
          <li className="lg:mt-8">
            If you need help- remember to touch the help button <br/> at the top of
            the screen.
          </li>
          {/* Render the clicked areas as a list */}
          {submitted && <p className="lg:mt-5">You submitted:</p>}
          <div className="grid grid-cols-2 gap-4">
            {" "}
            {[...Array(numColumns)].map((_, colIndex) => (
              <ol
                key={colIndex}
                start={
                  colIndex * Math.ceil(clickedAreas.length / numColumns) + 1
                }
                className="list-decimal lg:mt-2 lg:ml-0 md:ml-14"
              >
                {clickedAreas
                  .slice(
                    colIndex * Math.ceil(clickedAreas.length / numColumns),
                    (colIndex + 1) * Math.ceil(clickedAreas.length / numColumns)
                  )
                  .map((area, index) => (
                    <li key={index}>{area}</li>
                  ))}
                {!submitted && (
                  <button
                    className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded mt-4"
                    onClick={handleSubmit}
                  >
                    Submit
                  </button>
                )}
              </ol>
            ))}
          </div>
          {submitted && <div className="lg:mt-1">Submitted successfully!</div>}
        </ol>
        <BodyMap onAreaClick={handleAreaClick} />
      </div>
    </div>
  );
};

export default Section4;
