import React, { useState, useEffect, useCallback } from "react";
import BodyMap from "../BodyMapNew";
import axios from "axios";
import headAudio from "../../assets/audios/Head.mp3";
import stomachAudio from "../../assets/audios/Tummy.mp3";
import mouthAudio from "../../assets/audios/Mouth.mp3";
import armAudio from "../../assets/audios/Arm.mp3";
import footAudio from "../../assets/audios/Legs.mp3";
import coin from "../../assets/Icons/Coin.png";
import coins from "../../assets/audios/coins.mp3";

const Section1 = ({ carrier }) => {
  const [questionIndex, setQuestionIndex] = useState(0);
  const [isTransitioning, setIsTransitioning] = useState(false);
  const [shake, setShake] = useState(false);
  const [audioQueue, setAudioQueue] = useState([]);
  const [isPlaying, setIsPlaying] = useState(false);

  const audioPlay = () => {
    // Play celebration audio when the component mounts
    const audio = new Audio(coins);
    audio.play();
  };

  const playNextAudio = useCallback(() => {
    if (audioQueue.length === 0) {
      setIsPlaying(false);
      return;
    }

    const nextAudioPath = audioQueue[0];
    const nextAudio = new Audio(nextAudioPath);

    nextAudio.play();
    setAudioQueue((queue) => queue.slice(1));

    nextAudio.onended = () => {
      setIsPlaying(false);
    };
  }, [audioQueue]);

  useEffect(() => {
    if (!isPlaying && audioQueue.length > 0) {
      setIsPlaying(true);
      playNextAudio();
    }
  }, [audioQueue, isPlaying, playNextAudio]);
  const handleImageClick = (audioPath) => {
    setAudioQueue((queue) => [...queue, audioPath]);
  };
  const questions = [
    { ids: ["Head"], text: "1. Click on the head" },
    { ids: ["Stomach"], text: "2. Click on the tummy" },
    { ids: ["Head"], text: "3. Click on the mouth" },
    {
      ids: ["Left Arm", "Right Arm"],
      text: "4. Click on the arm",
    },
    {
      ids: ["Legs"],
      text: "5. Click on the Legs",
    },
  ];
  const audioMap = {
    Head: headAudio,
    Stomach: stomachAudio,
    Mouth: mouthAudio,
    "Left Arm": armAudio,
    "Right Arm": armAudio,
    Legs: footAudio,
  };
  const handleAreaClick = (areaTitle) => {
    console.log("Area Title:", areaTitle);
    const audioFile = audioMap[areaTitle];
    if (
      questions[questionIndex] &&
      questions[questionIndex].text.includes("mouth")
    ) {
      handleImageClick(mouthAudio);
    } else if (audioMap[areaTitle]) {
      handleImageClick(audioMap[areaTitle]);
    }
    if (questions[questionIndex].ids.includes(areaTitle)) {
      setIsTransitioning(true);
      setTimeout(() => {
        setIsTransitioning(false);
        setQuestionIndex((prevIndex) => prevIndex + 1);
      }, 500);
    } else {
      setShake(true);
      setTimeout(() => setShake(false), 500);
    }
  };

  useEffect(() => {
    if (questionIndex === questions.length) {
      // Hit the API to save mission activity
      saveMissionActivity();
      audioPlay();
      sessionStorage.setItem("taskCompleted", "true");
    }
  }, [questionIndex]);

  let userId = carrier.userId;
  const saveMissionActivity = async () => {
    try {
      const payload = [
        {
          userId: userId,
          missionId: "M2",
          pageNum: 16,
          activityId: "M25BodyPartActivity",
          activityValue: "Completed",
        },
      ];
      const response = await axios.post(
        "https://api.feelingbetterasd.com/mission/activity/saveMissionActivityDetails",
        payload
      );
      console.log("Response:", response.data);
    } catch (error) {
      console.error("Error saving mission activity:", error);
    }
  };

  return (
    <div className="w-80 h-72 text-sm max-w-4xl bg-transparent md:w-[60%] lg:h-96 xl:h-1/2 xl:pr-7 fadeIn">      
        <h2 className="text-xl font-bold mb-2 text-center text-[#F79D84] md:text-2xl lg:text-5xl font-gillSans flex items-center justify-center">
          Task
          <img src={coin} className="w-12 ml-2 heartbeat" alt="coin" />
        </h2>
      
      <div className="mt-10 flex flex-col">
        <p className=" text-base text-gray-700 lg:text-xl font-gillSans">
          Can you touch the 5 body parts on the body map?
        </p>
        <div className="text-gray-700 text-base flex flex-row -mt-12 justify-around items-center font-gillSans">
          <p
            className={`lg:text-xl mb-4 mt-14 transition-opacity duration-500 ${
              isTransitioning ? "opacity-0" : "opacity-100"
            }`}
          >
            {questions[questionIndex]
              ? questions[questionIndex].text
              : "You have completed the quiz!"}
          </p>

          {questionIndex < questions.length && (
            <div
              className={`transition-opacity duration-500 ${
                isTransitioning ? "opacity-0" : "opacity-100"
              } ${shake ? "shake" : ""}`}
            >
              <BodyMap onAreaClick={handleAreaClick} />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Section1;
